.test-media-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.test-media-buttons button {
  flex-grow: 1;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  font: inherit;
  outline: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  transition: color 0.3s ease, border-bottom-color 0.3s ease;
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.test-media-buttons button:hover {
  color: #c28e2b;
  border-bottom: 1px solid #c28e2b;
}

.test-media-button.active {
  color: #c28e2b;
  border-bottom: 1px solid #c28e2b;
}

.test-media-buttons-image {
  border-color: transparent;
}

.test-property-no-image-container {
  position: relative;
  width: 95%;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.test-media-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.test-property-page-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.test-property-page-name {
  margin-bottom: 0;
  color: #c28e2b;
  font-family: "BDSans-Regular";
  font-weight: 500;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.test-property-page-location {
  margin-bottom: 0;
  color: #efede7;
  font-family: "Open Sans", sans-serif;
  font-weight: 200;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.test-property-page-content {
  color: #efede7;
  font-family: "Open Sans", sans-serif;
}

.test-carousel-container {
  /* position: relative; */
  width: 100%;
  margin-top: 80px;
  overflow: hidden;
}

.test-carousel {
  position: relative;
  display: flex;
  transition: transform 0.5s ease;
  transition-behavior: normal;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  transition-delay: 0s;
  transition-property: transform;
  justify-content: center;
  flex-direction: column;
}

.test-carousel-image {
  width: 100%;
  height: 100%;
}


@media (max-width: 839px) {
  .test-carousel-container {
    margin-top: 30px;
  }

  .test-property-container {
    width: 90%;
  }

  .test-media-buttons-container {
    height: 50px;
    width: 100%;
  }

  .test-media-buttons-container.no-one-property {
    width: 100%;
  }

  .test-media-buttons-container.no-two-properties {
    width: 60%;
  }

  .test-media-buttons {
    margin-bottom: 0px;
    width: 58%;
  }

  .test-media-buttons.no-one-property {
    margin-left: 20px;
    width: 6rem;
  }

  .test-media-buttons.no-two-properties {
    margin-left: 20px;
    width: 6rem;
    margin-left: 10px;
    width: 3rem;
  }

  .test-media-buttons.no-one-properties button {
    margin-right: 20px;
  }

  /* 58% */

  .test-media-buttons button {
    font-size: 17px;
    height: 30px;
  }

  .test-media-button.active {
    border-bottom: none;
  }

  .test-media-buttons button:hover {
    border-bottom: none;
  }

  .test-media-button .media-text {
    display: none;
  }

  .test-media-buttons-image {
    width: 30px;
    height: 30px;
    margin-right: 0px;
  }

  .test-media-buttons-image.small-image {
    width: 28px;
    height: 28px;
    margin-right: 0px;
  }

  .test-property-no-image-container {
    height: 250px;
  }

  .test-property-no-image-icon {
    width: 80px;
  }

  .test-property-page-column {
    height: 120px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .test-property-page-name {
    margin-top: 0px;
    width: 500px;
    font-size: 25px;
  }

  .test-property-page-location {
    margin-top: 3px;
    width: 250px;
    font-size: 16px;
  }

  .test-property-page-content {
    margin-top: 60px;
  }
}

@media (min-width: 840px) {
  .test-property-container {
    width: 70%;
  }

  .test-media-buttons-container {
    height: 50px;
    width: 100%;
  }

  .test-media-buttons-container.no-one-property {
    width: 40%;
  }

  .test-media-buttons-container.no-two-properties {
    width: 80%;
  }

  .test-media-buttons {
    margin-bottom: 0px;
    width: 58%;
  }

  .test-media-buttons button {
    font-size: 17px;
    margin-right: 20px;
    height: 30px;
  }

  .test-media-buttons-image {
    width: 20px;
    height: 20px;
    margin-right: 9px;
  }

  .test-property-no-image-container {
    height: 350px;
  }

  .test-property-no-image-icon {
    width: 80px;
  }

  .test-property-page-row {
    height: 45px;
    margin-top: 20px;
  }

  .test-property-page-name {
    margin-top: 0px;
    width: 500px;
    font-size: 32px;
  }

  .test-property-page-location {
    margin-top: 3px;
    width: 350px;
    font-size: 22px;
  }

  .test-property-page-content {
    margin-top: 40px;
  }
}

@media (min-width: 1024px) {
  .test-property-container {
    width: 70%;
  }

  .test-media-buttons-container {
    height: 50px;
    width: 100%;
  }

  .test-media-buttons-container.no-one-property {
    width: 40%;
  }

  .test-media-buttons-container.no-two-properties {
    width: 80%;
  }

  .test-media-buttons {
    margin-bottom: 0px;
    width: 58%;
  }

  .test-media-buttons button {
    font-size: 17px;
    margin-right: 20px;
    height: 30px;
  }

  .test-media-buttons-image {
    width: 20px;
    height: 20px;
    margin-right: 9px;
  }

  .test-property-no-image-container {
    height: 400px;
  }

  .test-property-no-image-icon {
    width: 85px;
  }

  .test-property-page-row {
    margin-top: 20px;
  }

  .test-property-page-name {
    margin-top: 0px;
    height: auto;
    width: 500px;
    font-size: 35px;
  }

  .test-property-page-location {
    margin-top: 10px;
    width: 500px;
    font-size: 25px;
  }

  .test-property-page-content {
    margin-top: 50px;
  }
}

@media (min-width: 1280px) {
  .test-property-container {
    width: 60%;
  }

  .test-media-buttons-container {
    height: 60px;
    width: 100%;
  }

  .test-media-buttons-container.no-one-property {
    width: 40%;
  }

  .test-media-buttons-container.no-two-properties {
    width: 80%;
  }

  .test-media-buttons {
    height: 38px;
    margin-bottom: 10px;
    width: 58%;
  }

  .test-media-buttons button {
    font-size: 19px;
    margin-right: 20px;
  }

  .test-media-buttons-image {
    width: 23px;
    height: 23px;
    margin-right: 9px;
  }

  .test-property-no-image-container {
    height: 480px;
  }

  .test-property-no-image-icon {
    width: 95px;
  }

  .test-media-buttons {
    height: 40px;
    margin-bottom: 10px;
    width: 60%;
  }

  .test-property-page-row {
    margin-top: 28px;
  }

  .test-property-page-name {
    margin-top: 0px;
    height: auto;
    width: 500px;
    font-size: 38px;
  }

  .test-property-page-location {
    margin-top: 10px;
    width: 500px;
    font-size: 28px;
  }

  .test-property-page-content {
    margin-top: 50px;
  }
}

@media (min-width: 1536px) {
  .test-property-container {
    width: 60%;
  }

  .test-media-buttons-container {
    height: 60px;
    width: 100%;
  }

  .test-media-buttons-container.no-one-property {
    width: 40%;
  }

  .test-media-buttons-container.no-two-properties {
    width: 80%;
  }

  .test-media-buttons {
    height: 40px;
    margin-bottom: 10px;
    width: 60%;
  }

  .test-media-buttons button {
    font-size: 20px;
    margin-right: 20px;
  }

  .test-media-buttons-image {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  .test-property-no-image-container {
    height: 500px;
  }

  .test-property-no-image-icon {
    width: 100px;
  }

  .test-media-buttons {
    height: 40px;
    margin-bottom: 10px;
    width: 60%;
  }

  .test-property-page-row {
    margin-top: 30px;
  }

  .test-property-page-name {
    margin-top: 0px;
    height: auto;
    width: 500px;
    font-size: 40px;
  }

  .test-property-page-location {
    margin-top: 10px;
    width: 500px;
    font-size: 30px;
  }

  .test-property-page-content {
    margin-top: 50px;
  }
}
