@font-face {
  font-family: "Higuen-Serif";
  src: url("../../fonts/Higuen-Serif.otf");
}

@font-face {
  font-family: "CormorantGaramond-Medium";
  src: url("../../fonts/CormorantGaramond-Medium.ttf") format("truetype");
}

.about-us-container {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #011932;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.about-us-content {
  position: absolute;
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-us-content-text {
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.about-us-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* .about-us-sub-row {
  width: 70%;
  display: flex;
  flex-direction: row;
} */

.about-us-title {
  font-size: 50px;
  margin-top: 100px;
  margin-bottom: 50px;
  font-weight: lighter;
  font-family: "Higuen-Serif";
  color: #c28e2b;
}

.about-us-text {
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 50px;
  font-weight: lighter;
  font-family: "CormorantGaramond-Medium";
  color: #efede7;
  text-align: center;
}

.about-us-square {
  border: 2px solid #c28e2b;
  width: 60%;
  height: 700px;
}

/* .about-us-square {
  border: 2px solid #c28e2b;
  width: 600px;
  height: 400px;
  clip-path: inset(20% 25%) 50% round 25%, inset(20% 75%) 50% round 25%;
} */
