.dashboard-real-estate-highlight-container {
  height: 100%;
  background: linear-gradient(90deg, #000000, #3533cd);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dashboard-real-estate-highlight-column {
  position: absolute;
  width: 90%;
  height: 100%;
  /* top: 100; */
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.dashboard-real-estate-highlight-title {
  left: 0;
  font-size: 50px;
  margin-top: 40px;
  margin-bottom: 30px;
  font-weight: lighter;
  font-family: "Higuen-Serif";
  color: #c28e2b;
  display: flex;
  flex-direction: row;
}

.dashboard-real-estate-link {
  left: 0;
  font-size: 50px;
  font-weight: lighter;
  font-family: "Higuen-Serif";
  color: white;
  text-decoration: none;
}

.dashboard-real-estate-link:hover {
  color: #c28e2b;
}

.dashboard-real-estate-highlight-divider {
  height: 45px;
  border-left: 2px solid #c28e2b;
  margin-left: 15px;
  margin-right: 15px;
}

.dashboard-real-estate-highlight-divider-white {
  height: 45px;
  border-left: 2px solid white;
  margin-left: 15px;
  margin-right: 15px;
}

.dashboard-highlight-container {
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dashboard-highlight-row {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.dashboard-highlight-row > * {
  margin-right: 50px;
}

.dashboard-highlight-row > *:last-child {
  margin-right: auto;
}

.real-estate-highlight-property-card-link {
  text-decoration: none;
  width: 30%;
  height: 100%;
}

.add-more-container {
  width: 250px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  border: 3px solid #c28e2b;
  background-color: #c9b184;
  color: black;
  border-radius: 8px;
  cursor: pointer;
}

.add-more-container:hover {
  background-color: #b89657;
}

.plus-icon {
  width: 50px;
}

/* Adiciona um efeito de desfoque ao fundo quando o popup está aberto */
.blur-background {
  filter: blur(5px);
  pointer-events: none;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.popup-content::-webkit-scrollbar {
  width: 7px; /* largura do scrollbar */
}

.popup-content::-webkit-scrollbar-track {
  background: transparent; /* cor de fundo do scrollbar */
}

.popup-content::-webkit-scrollbar-thumb {
  background-color: #888; /* cor do scrollbar */
  border-radius: 6px; /* borda do scrollbar */
}

.popup-content::-webkit-scrollbar-thumb:hover {
  background: #555; /* cor do scrollbar ao passar o mouse */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.thumbnail-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.no-image-icon-thumbnail {
  width: 50px;
  height: 50px;
}

.properties-table {
  width: 100%;
  border-collapse: collapse;
}

.properties-table th,
.properties-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.properties-table tr:last-child th,
.properties-table tr:last-child td {
  border-bottom: none;
}

.properties-table th {
  background-color: #f4f4f4;
}

.table-header-title {
  font-family: "Open Sans", sans-serif;
}
