.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.class-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 18px;
  scroll-snap-align: start;
  background-color: #013469;
  /* border: 1px solid #0f54f0; */
  border-radius: 13px;
  height: 150px;
  width: 400px;
  flex-shrink: 0;
  margin-bottom: 10px;
  box-shadow: 0px 0px 100px 10px rgba(0, 0, 0, 0.5);
}

/* .class-modal {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 14px;
  color: #000000;
  mix-blend-mode: normal;
  flex: none;
  order: 1;
  flex-grow: 0;
} */

.export-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.export-title {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 27px;
  color: white;
  margin-bottom: 0px;
}

.close-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
}

.button-cancel {
  width: 99px;
  height: 36px;
  background: #ffffff;
  border-radius: 10px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  /* border: 0.5px solid #c28e2b; */
  font-size: 14px;
  color: #0f0f0f;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button-cancel:hover {
  background: #c28e2b;
}

.close-button-confirm {
  background: #c28e2b;
  border-radius: 10px;
  width: 99px;
  height: 36px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: black;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.close-button-confirm:hover {
  background: #e3b155;
}

.close-form--submit-loading {
  position: relative;
}

.close-form--submit-loading::after {
  content: "";
  position: absolute;
  top: 20%;
  left: 40%;
  width: 20px;
  height: 20px;
  border: 4px solid #011932;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
