.dashboard-container {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #011932;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.dashboard-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.dashboard-content-column {
  width: 96%;
  max-width: 1725px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

/* .sidebar-container {
  width: 96px;
  height: 100%;
} */