* {
  margin: 0;
  padding: 0;
  /* line-height: 1.75rem; */
}

.app-container {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  position: relative;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* object-position: 50% 50%; */
  z-index: -1;
}

.test-title {
  letter-spacing: 0.2cm;
  font-weight: bold;
  margin-top: 0;
  font-family: "BDSans-Regular";
  color: #efede7;
  margin-bottom: 30px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.test-subtitle {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.test-subsubtitle {
  letter-spacing: 0.2cm;
  margin: 0;
  font-family: "CormorantGaramond-Medium";
  color: #efede7;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.container-header {
  position: absolute;
  right: 0;
  z-index: 1000;
  margin-left: auto;
  margin-right: auto;
  /* padding: 0.5rem; */
  display: flex;
  justify-content: end;
  align-items: center;
  /* width: 100%; */
}

.container-options {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.menu-container {
  position: fixed;
  top: -100%;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: #011932;
  /* justify-content: center;
  align-items: center;
  text-align: center; */
  display: flex;
  transition: top 0.5s ease-in-out;
  overflow: hidden;
}

.menu-subcontainer {
  height: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
  overflow: hidden;
}

.menu-subcontainer-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.menu-subcontainer-column {
  height: 30vh;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  overflow: hidden;
  /* font-size: 2rem; */
}

.menu-container.active {
  top: 0;
}

.menu-close {
  background: none;
  border: none;
  cursor: pointer;
}

.arrow-icon {
  width: 1.5rem;
  height: 1.5rem;
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.container-menu {
  z-index: 1000;
  background: none;
  border: none;
  cursor: pointer;
}

/* .menu-icon-active {
  z-index: 1000;
  transform: rotate(90deg);
} */

.container-suboption {
  color: white;
  font-family: "Open Sans", sans-serif;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.container-suboption:hover {
  color: #c28e2b;
}

.container-suboption.active,
.container-suboption.active:visited {
  color: #c28e2b;
}

.menu-subcontainer-option.active,
.menu-subcontainer-option.active:visited {
  color: #c28e2b;
}

.container-suboption-flag {
  color: white;
  font-family: "Open Sans", sans-serif;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.container-flag {
  border-color: transparent;
}

.container-dropdown-content {
  position: absolute;
  top: 6.5vh;
  /* right: 1.5vw; */
}

.container-dropdown-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-subcontainer-option {
  color: white;
  font-family: "Open Sans", sans-serif;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.menu-subcontainer-option:hover {
  color: #c28e2b;
}

.menu-line {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.menu-container-flag {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* .menu-icon {
  transform: rotate(90deg); 
  transition: transform 1s ease;
} */

@media (max-width: 163px) {
  .test-title {
    font-size: 1.2rem;
  }

  .test-subtitle {
    font-size: 2.5rem;
  }

  .test-subsubtitle {
    font-size: 1.4rem;
    /* font-size: 34px; */
  }

  .menu-subcontainer-column {
    font-size: 1.3rem;
  }

  .container-flag {
    height: 2.1rem;
    width: auto;
  }

  .menu-container-flag {
    display: flex;
    align-items: center;
    width: 3.6rem;
  }

  .menu-subcontainer-flag {
    height: 1.3rem;
    display: flex;
    align-items: center;
  }

  .menu-subcontainer-flag-image {
    height: 100%;
    width: auto;
  }
}

@media (min-width: 163px) {
  .test-title {
    font-size: 1.2rem;
  }

  .test-subtitle {
    font-size: 2.5rem;
  }

  .test-subsubtitle {
    font-size: 1.4rem;
    /* font-size: 34px; */
  }

  .menu-subcontainer-column {
    font-size: 1.5rem;
  }

  .container-flag {
    height: 2.1rem;
    width: auto;
  }

  .menu-container-flag {
    display: flex;
    align-items: center;
    width: 4rem;
  }

  .menu-subcontainer-flag {
    height: 1.5rem;
    display: flex;
    align-items: center;
  }

  .menu-subcontainer-flag-image {
    height: 100%;
    width: auto;
  }
}

@media (min-width: 263px) {
  .test-title {
    font-size: 1.2rem;
  }

  .test-subtitle {
    font-size: 2.5rem;
  }

  .test-subsubtitle {
    font-size: 1.4rem;
    /* font-size: 34px; */
  }

  .menu-subcontainer-column {
    font-size: 1.7rem;
  }

  .container-flag {
    height: 2.1rem;
    width: auto;
  }

  .menu-container-flag {
    display: flex;
    align-items: center;
    width: 4.6rem;
  }

  .menu-subcontainer-flag {
    height: 1.7rem;
    display: flex;
    align-items: center;
  }

  .menu-subcontainer-flag-image {
    height: 100%;
    width: auto;
  }
}

@media (min-width: 307px) {
  .test-title {
    font-size: 1.2rem;
  }

  .test-subtitle {
    font-size: 2.5rem;
  }

  .test-subsubtitle {
    font-size: 1.4rem;
    /* font-size: 34px; */
  }

  .menu-subcontainer-column {
    /* font-size: 1.7rem; */
    font-size: 2rem;
  }

  .container-flag {
    height: 2.1rem;
    width: auto;
  }

  .menu-container-flag {
    display: flex;
    align-items: center;
    width: 5.4rem;
  }

  .menu-subcontainer-flag {
    height: 2.1rem;
    display: flex;
    align-items: center;
  }

  .menu-subcontainer-flag-image {
    height: 100%;
    width: auto;
  }
}

@media (min-width: 400px) {
  .test-title {
    font-size: 1.2rem;
  }

  .test-subtitle {
    font-size: 2.5rem;
  }

  .test-subsubtitle {
    font-size: 1.4rem;
    /* font-size: 34px; */
  }

  .container-header {
    max-width: 400px;
    /* padding-right: 10rem; */
  }

  .menu-subcontainer {
    max-width: 475px;
  }

  .container-suboption {
    font-size: 1.3rem;
  }

  .container-flag {
    height: 2.1rem;
    width: auto;
  }

  .menu-container-flag {
    display: flex;
    align-items: center;
    width: 5.4rem;
  }

  .menu-subcontainer-flag {
    height: 2.1rem;
    display: flex;
    align-items: center;
  }

  .menu-subcontainer-flag-image {
    height: 100%;
    width: auto;
  }
}

/* xs */
@media (min-width: 475px) {
  .test-title {
    font-size: 1.2rem;
  }

  .test-subtitle {
    font-size: 3.3rem;
  }

  .test-subsubtitle {
    font-size: 1.4rem;
    /* font-size: 34px; */
  }

  .container-header {
    max-width: 475px;
    /* padding-right: 10rem; */
  }

  .menu-subcontainer {
    max-width: 475px;
  }

  .container-suboption {
    font-size: 1.4rem;
  }

  .container-flag {
    height: 2.2rem;
    width: auto;
  }

  .menu-container-flag {
    display: flex;
    align-items: center;
    width: 5.8rem;
  }

  .menu-subcontainer-flag {
    height: 2.2rem;
    display: flex;
    align-items: center;
  }

  .menu-subcontainer-flag-image {
    height: 100%;
    width: auto;
  }
}

/* sm */
@media (min-width: 640px) {
  .test-title {
    font-size: 1.2rem;
  }

  .test-subtitle {
    font-size: 4.3rem;
  }

  .test-subsubtitle {
    font-size: 1.4rem;
    /* font-size: 34px; */
  }

  .container-header {
    max-width: 640px;
    padding-top: 2vh;
    padding-bottom: 2vh;
    padding-right: 0.5vw;
    padding-left: 0.5vw;
    margin-left: auto;
    margin-right: 1vw;
  }

  .menu-subcontainer {
    max-width: 640px;
  }

  .container-menu {
    display: none;
  }

  .menu-container {
    display: none;
  }

  .menu-container.active {
    display: none;
  }

  .menu-subcontainer {
    display: none;
  }

  .menu-subcontainer.active {
    display: none;
  }

  .container-suboption {
    font-size: 1.5rem;
  }

  .container-flag {
    height: 2.3rem;
    width: auto;
  }

  .container-arrow {
    height: 1.1vh;
    width: auto;
    margin-left: 9px;
  }
}

@media (max-width: 640px) {
  .container-header {
    padding-top: 2vh;
    padding-bottom: 2vh;
    padding-right: 5vw;
    padding-left: 5vw;
  }

  .container-options {
    display: none;
  }

  .menu-container.active {
    display: flex;
  }

  .menu-subcontainer.active {
    display: flex;
  }
}

/* md */
@media (min-width: 768px) {
  .test-title {
    font-size: 1.2rem;
  }

  .test-subtitle {
    font-size: 5.3rem;
  }

  .test-subsubtitle {
    font-size: 1.4rem;
    /* font-size: 34px; */
  }

  .container-header {
    max-width: 768px;
  }

  .menu-subcontainer {
    max-width: 768px;
  }

  .container-suboption {
    font-size: 1.6rem;
  }

  .container-flag {
    height: 2.4rem;
    width: auto;
  }

  .container-arrow {
    height: 1.2vh;
    width: auto;
    margin-left: 11px;
  }
}

/* lg */
@media (min-width: 1024px) {
  .test-title {
    font-size: 1.4rem;
  }

  .test-subtitle {
    font-size: 6.5rem;
  }

  .test-subsubtitle {
    font-size: 1.6rem;
    /* font-size: 34px; */
  }

  .container-header {
    max-width: 1024px;
  }

  .container-suboption {
    font-size: 1.7rem;
  }

  .container-flag {
    height: 2.5rem;
    width: auto;
  }

  .container-arrow {
    height: 1.3vh;
    width: auto;
    margin-left: 13px;
  }
}

/* xl */
@media (min-width: 1280px) {
  .test-title {
    font-size: 1.6rem;
  }

  .test-subtitle {
    font-size: 7rem;
  }

  .test-subsubtitle {
    font-size: 1.8rem;
    /* font-size: 34px; */
  }

  .container-header {
    max-width: 1280px;
  }

  .container-suboption {
    font-size: 1.8rem;
  }

  .container-flag {
    height: 2.6rem;
    width: auto;
  }

  /* .container-suboption-flag {
    width: 6.2vw;
  } */

  .container-arrow {
    height: 1.4vh;
    width: auto;
    margin-left: 15px;
  }
}

/* 2xl */
@media (min-width: 1536px) {
  .test-title {
    font-size: 1.8rem;
  }

  .test-subtitle {
    font-size: 7.5rem;
  }

  .test-subsubtitle {
    font-size: 2rem;
    /* font-size: 34px; */
  }

  .container-header {
    max-width: 1536px;
  }

  .container-suboption {
    font-size: 1.9rem;
  }

  .container-flag {
    height: 2.7rem;
    width: auto;
  }

  /* .container-suboption-flag {
    width: 5.8vw;
  } */

  .container-arrow {
    height: 1.5vh;
    width: auto;
    margin-left: 15px;
  }
}
