.app-container-imoveis {
  position: relative;
  width: 100%;
  background-color: #011932;
  z-index: 1;
}

.app-container-imoveis.active-dropdown {
  height: 100%;
  overflow: hidden;
}

.app-container-imoveis.no-properties {
  height: 100%;
  overflow: hidden;
}

.test-video-container {
  /* position: absolute;
  top: 0;
  left: 0; */
  position: relative;
  width: 100%;
  z-index: -1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.test-video-container.no-properties {
  height: 100%;
}

.test-video-column {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.test-mute-button {
  position: absolute;
  bottom: 20px;
  right: 30px;
  z-index: 1;
  background-color: #c28e2b;
  border-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.test-video-text {
  color: white;
  text-align: center;
  font-family: "BDSans-Regular";
  font-weight: 500;
}

.test-real-estate-home-button {
  background-color: #c28e2b;
  color: white;
  border-color: transparent;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.test-real-estate-home-button:hover {
  background-color: #aa781b;
}

.test-real-estate-home-video {
  height: 750px;
  width: 100%;
}

.test-real-estate-home-video.no-properties {
  height: 100%;
}

.test-real-estate-home-row {
  position: relative;
  z-index: 1;
  background-image: url("../../assets/images/highlight_background.png");
  width: 100%;
  overflow: hidden;
  background-position: center;
  background-size: cover;
}

.test-real-estate-home-properties-carousel {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.test-properties-home-carousel-container {
  width: 70%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.test-properties-home-real-estate-card-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

.test-properties-home-row-property-card-link-recent {
  height: 100%;
  width: 30%;
  text-decoration: none;
  color: inherit;
}

.test-properties-home-real-estate-card-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

.test-properties-home-no-image-container-general {
  height: 100%;
  width: 60%;
  background-color: #efede7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px 0px 0px 10px;
}

.test-properties-home-carousel-image {
  display: block;
  height: 100%;
  width: 60%;
  border-radius: 10px 0px 0px 10px;
}

.test-properties-home-carousel-image-highlight {
  display: block;
  height: 100%;
  width: 60%;
  border-radius: 10px 0px 0px 10px;
}

.test-real-estate-content-row {
  height: 90%;
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
}

.test-real-state-text-name-row {
  color: #c28e2b;
  font-family: "BDSans-Regular";
  font-weight: bold;
}

.test-property-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.test-real-state-text-location-row {
  margin-bottom: 0px;
  color: black;
  font-family: "BDSans-Regular";
}

.test-real-state-text-type-row {
  margin-bottom: 0;
  color: #c28e2b;
  font-family: "CormorantGaramond-Medium";
}

.test-real-state-text-price-row {
  margin-bottom: 0;
  color: black;
  font-family: "CormorantGaramond-Medium";
}

.test-normal-price {
  color: #c28e2b;
}

.test-real-estate-home-title-highlight-container {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.test-real-estate-home-title-container {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.test-properties-home-carousel-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.test-real-estate-home-property-card-link {
  position: relative;
  z-index: 1;
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.test-properties-home-no-image-container-general-recent {
  background-color: #efede7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0px 0px;
}

.test-real-estate-content-row-recent {
  /* height: 90%; */
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
}

.test-real-estate-home-title {
  font-weight: lighter;
  font-family: "Higuen-Serif";
  color: #c28e2b;
}

.test-carousel-dots {
  left: 0;
  right: 0;
  display: flex;
  justify-content: center; /* Alinha os itens ao centro */
}

.test-carousel-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.test-carousel-dot.active {
  background-color: #c28e2b;
}

.test-properties-home-row-property-card-link {
  height: 90%;
  text-decoration: none;
  color: inherit;
}

.test-reserved-banner {
  position: absolute;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  color: white;
  text-align: center;
  justify-content: center;
  line-height: 2;
  font-weight: bold;
  z-index: 1;
  border-radius: 3px 7px 7px 0;
  /* transform: rotate(-45deg);
  transform-origin: left top;  */
  /* clip-path: polygon(21.8% 0, 68% 0, 81% 100%, 8.5% 100%); */
}

.test-banner-triangle {
  position: absolute;
  z-index: 1;
  clip-path: polygon(100% 0%, 0% 0%, 100% 100%);
}

.test-reserved-banner.reserved {
  background-color: #c28e2b;
}

.test-reserved-banner.sold {
  background-color: #ff0000;
}

.test-banner-triangle.reserved {
  background-color: #6e5019;
}

.test-banner-triangle.sold {
  background-color: #671111;
}

.test-old-price {
  position: relative;
  color: #c28e2b;
}

.test-old-price::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #c28e2b;
}

.recent-dots-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.recent-dot {
  width: 10px;
  height: 10px;
  background-color: #bbb;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.recent-dot.active {
  background-color: #555;
}

@media (max-width: 307px) {
  .test-video-column {
    height: 7rem;
    top: 53%;
  }

  .test-video-column.no-properties {
    top: 60%;
  }

  .test-video-text {
    width: 23rem;
    font-size: 1rem;
  }

  .test-mute-button {
    height: 2.7rem;
    width: 2.7rem;
  }

  .test-mute-icon {
    height: 1.7rem;
    width: 1.7rem;
  }

  .test-real-estate-home-button {
    height: 1.7rem;
    width: 8.8rem;
    font-size: 13px;
  }
}

@media (min-width: 307px) {
  .test-video-column {
    height: 7rem;
    top: 53%;
  }

  .test-video-column.no-properties {
    top: 63%;
  }

  .test-video-text {
    width: 23rem;
    font-size: 1rem;
  }

  .test-mute-button {
    height: 2.7rem;
    width: 2.7rem;
  }

  .test-mute-icon {
    height: 1.7rem;
    width: 1.7rem;
  }

  .test-real-estate-home-button {
    height: 1.7rem;
    width: 8.8rem;
    font-size: 13px;
  }
}

@media (min-width: 400px) {
  .test-video-column {
    height: 7rem;
    top: 53%;
  }

  .test-video-column.no-properties {
    top: 63%;
  }

  .test-video-text {
    width: 23rem;
    font-size: 1rem;
  }

  .test-mute-button {
    height: 2.7rem;
    width: 2.7rem;
  }

  .test-mute-icon {
    height: 1.7rem;
    width: 1.7rem;
  }

  .test-real-estate-home-button {
    height: 1.7rem;
    width: 9rem;
    font-size: 13px;
  }
}

@media (max-width: 475px) {
}

@media (min-width: 475px) {
  .test-video-column {
    height: 8rem;
    top: 52%;
  }

  .test-video-column.no-properties {
    top: 63%;
  }

  .test-video-text {
    width: 28rem;
    font-size: 1.2rem;
  }

  .test-mute-button {
    height: 2.7rem;
    width: 2.7rem;
  }

  .test-mute-icon {
    height: 1.7rem;
    width: 1.7rem;
  }

  .test-real-estate-home-button {
    height: 1.9rem;
    width: 9.4rem;
    font-size: 15px;
  }
}

@media (min-width: 640px) {
  .test-video-column {
    height: 10rem;
    top: 50%;
  }

  .test-video-column.no-properties {
    top: 60%;
  }

  .test-video-text {
    width: 40rem;
    font-size: 1.5rem;
  }

  .test-mute-button {
    height: 2.7rem;
    width: 2.7rem;
  }

  .test-mute-icon {
    height: 1.7rem;
    width: 1.7rem;
  }

  .test-real-estate-home-button {
    height: 2.1rem;
    width: 9.6rem;
    font-size: 17px;
  }
}

@media (max-width: 530px) {
  .test-real-estate-home-title {
    font-size: 34px;
  }

  .test-properties-home-real-estate-card-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  }

  .test-property-column {
    height: 21%;
  }

  .test-properties-home-no-image-container-general {
    height: 60%;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
  }

  .test-properties-home-carousel-image {
    height: 60%;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
  }

  .test-real-estate-content-row {
    height: 38%;
    width: 90%;
  }

  .test-properties-home-real-estate-card-row {
    height: 100%;
  }

  .test-properties-home-carousel-container {
    height: 70%;
    width: 80%;
  }

  .test-real-estate-home-title {
    font-size: 30px;
  }

  .test-real-state-text-name-row {
    font-size: 17px;
  }

  .test-real-state-text-location-row {
    font-size: 14px;
    width: 90%;
  }

  .test-real-state-text-type-row {
    font-size: 33px;
  }

  .test-real-state-text-price-row {
    margin-bottom: 0;
    font-size: 24px;
    margin-bottom: 5px;
  }

  .test-reserved-banner {
    font-size: 15px;
    top: 2.07rem;
    left: -0.85rem;
    width: 140px;
    height: 30px;
  }

  .test-banner-triangle {
    height: 10px;
    width: 13.8px;
    left: -0.9rem;
    top: 3.9rem;
  }

  .test-old-price::after {
    bottom: 12;
  }

  .test-properties-home-carousel-row {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .test-properties-home-real-estate-card-column {
    height: 94%;
  }

  .test-properties-home-row-property-card-link-recent {
    width: 100%;
  }

  .test-properties-home-no-image-container-general-recent {
    height: 60%;
    width: 100%;
  }

  .test-properties-home-carousel-image {
    height: 60%;
    width: 100%;
  }

  .test-real-estate-content-row-recent {
    height: 38%;
    width: 90%;
  }

  .test-real-estate-home-title {
    width: 70%;
  }

  .real-estate-home-see-more {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .test-properties-home-carousel-image-highlight {
    height: 60%;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
  }
}

@media (min-width: 530px) and (max-width: 767px) {
  .test-real-estate-home-title {
    font-size: 34px;
  }

  .test-properties-home-real-estate-card-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  }

  .test-property-column {
    height: 21%;
  }

  .test-properties-home-no-image-container-general {
    height: 60%;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
  }

  .test-properties-home-carousel-image {
    height: 60%;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
  }

  .test-real-estate-content-row {
    height: 38%;
    width: 90%;
  }

  .test-properties-home-real-estate-card-row {
    height: 100%;
  }

  .test-properties-home-carousel-container {
    height: 70%;
    width: 80%;
  }

  .test-real-estate-home-title {
    font-size: 30px;
  }

  .test-real-state-text-name-row {
    font-size: 17px;
  }

  .test-real-state-text-location-row {
    font-size: 14px;
    width: 90%;
  }

  .test-real-state-text-type-row {
    font-size: 33px;
  }

  .test-real-state-text-price-row {
    margin-bottom: 0;
    font-size: 24px;
    margin-bottom: 5px;
  }

  .test-reserved-banner {
    font-size: 15px;
    top: 2.07rem;
    left: -0.85rem;
    width: 140px;
    height: 30px;
  }

  .test-banner-triangle {
    height: 10px;
    width: 13.8px;
    left: -0.9rem;
    top: 3.9rem;
  }

  .test-old-price::after {
    bottom: 12;
  }

  .test-properties-home-carousel-row {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .test-properties-home-real-estate-card-column {
    height: 94%;
  }

  .test-properties-home-row-property-card-link-recent {
    width: 45%;
  }

  .test-properties-home-no-image-container-general-recent {
    height: 60%;
    width: 100%;
  }

  .test-properties-home-carousel-image {
    height: 60%;
    width: 100%;
  }

  .test-properties-home-carousel-image-highlight {
    height: 60%;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
  }

  .test-real-estate-content-row-recent {
    height: 43%;
    width: 90%;
  }
}

@media (min-width: 768px) {
  .test-video-column {
    height: 11rem;
    top: 50%;
  }

  .test-video-column.no-properties {
    top: 60%;
  }

  .test-video-text {
    width: 45rem;
    font-size: 1.6rem;
  }

  .test-mute-button {
    height: 2.8rem;
    width: 2.8rem;
  }

  .test-mute-icon {
    height: 1.8rem;
    width: 1.8rem;
  }

  .test-real-estate-home-button {
    height: 2.1rem;
    width: 9.6rem;
    font-size: 17px;
  }

  .test-property-column {
    height: 21%;
  }

  .test-real-estate-home-title {
    font-size: 34px;
  }

  .test-real-state-text-name-row {
    font-size: 17px;
  }

  .test-real-state-text-location-row {
    font-size: 14px;
    width: 90%;
  }

  .test-real-state-text-type-row {
    font-size: 33px;
  }

  .test-real-state-text-price-row {
    margin-bottom: 0;
    font-size: 24px;
  }

  .test-properties-home-carousel-container {
    height: 60%;
    width: 70%;
  }

  .test-properties-home-real-estate-card-row {
    height: 95%;
  }

  .test-real-estate-home-row {
    height: 500px;
  }

  .test-reserved-banner {
    font-size: 15px;
    top: 1.5rem;
    left: -0.8rem;
    width: 140px;
    height: 1.9rem;
  }

  .test-banner-triangle {
    height: 10px;
    width: 13.8px;
    left: -0.85rem;
    top: 3.3rem;
  }

  .test-old-price::after {
    bottom: 11;
  }

  .test-properties-home-real-estate-card-column {
    height: 94%;
  }

  .test-properties-home-carousel-row {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .test-real-estate-home-property-card-link {
    width: 300px;
    position: relative;
    z-index: 1;
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .test-properties-home-no-image-container-general-recent {
    height: 60%;
    width: 100%;
    background-color: #efede7;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 0px 0px;
  }

  .test-properties-home-carousel-image {
    height: 60%;
    width: 100%;
  }

  .test-real-estate-content-row-recent {
    /* height: 90%; */
    height: 43%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
  }

  .test-properties-home-row-property-card-link-recent.two-elements {
    width: 45%;
  }
}

@media (min-width: 1024px) {
  .test-video-column {
    height: 11rem;
    top: 50%;
  }

  .test-video-column.no-properties {
    top: 61%;
  }

  .test-video-text {
    width: 45rem;
    font-size: 1.7rem;
  }

  .test-mute-button {
    height: 2.8rem;
    width: 2.8rem;
  }

  .test-mute-icon {
    height: 1.8rem;
    width: 1.8rem;
  }

  .test-real-estate-home-button {
    height: 2.1rem;
    width: 9.6rem;
    font-size: 18px;
  }

  .test-property-column {
    height: 23%;
  }

  .test-real-estate-home-title {
    font-size: 36px;
  }

  .test-real-state-text-name-row {
    font-size: 24px;
  }

  .test-real-state-text-location-row {
    font-size: 16px;
    width: 90%;
  }

  .test-real-state-text-type-row {
    font-size: 35px;
  }

  .test-real-state-text-price-row {
    margin-bottom: 0;
    font-size: 26px;
  }

  .test-properties-home-carousel-container {
    height: 60%;
    width: 70%;
  }

  .test-properties-home-real-estate-card-row {
    height: 95%;
  }

  .test-real-estate-home-row {
    height: 540px;
  }

  .test-reserved-banner {
    font-size: 17px;
    top: 1.5rem;
    left: -0.8rem;
    width: 145px;
    height: 2.3rem;
  }

  .test-banner-triangle {
    height: 10px;
    width: 13.8px;
    left: -0.87rem;
    top: 3.8rem;
  }

  .test-old-price::after {
    bottom: 11;
  }

  .test-properties-home-real-estate-card-column {
    height: 96%;
  }

  .test-properties-home-carousel-row {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .test-real-estate-home-property-card-link {
    width: 300px;
    position: relative;
    z-index: 1;
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .test-properties-home-no-image-container-general-recent {
    height: 60%;
    width: 100%;
    background-color: #efede7;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 0px 0px;
  }

  .test-properties-home-carousel-image {
    height: 60%;
    width: 100%;
  }

  .test-real-estate-content-row-recent {
    /* height: 90%; */
    height: 38%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
  }
}

@media (min-width: 1280px) {
  .test-video-column {
    height: 12rem;
    top: 50%;
  }

  .test-video-column.no-properties {
    top: 61%;
  }

  .test-video-text {
    width: 50rem;
    font-size: 1.8rem;
  }

  .test-mute-button {
    height: 3rem;
    width: 3rem;
  }

  .test-mute-icon {
    height: 2rem;
    width: 2rem;
  }

  .test-real-estate-home-button {
    height: 2.3rem;
    width: 9.8rem;
    font-size: 19px;
  }

  .test-property-column {
    height: 23%;
  }

  .test-real-estate-home-title {
    font-size: 38px;
  }

  .test-real-state-text-name-row {
    font-size: 26px;
  }

  .test-real-state-text-location-row {
    font-size: 18px;
    width: 90%;
  }

  .test-real-state-text-type-row {
    font-size: 37px;
  }

  .test-real-state-text-price-row {
    margin-bottom: 0;
    font-size: 28px;
  }

  .test-properties-home-carousel-container {
    height: 60%;
    width: 70%;
  }

  .test-properties-home-real-estate-card-row {
    height: 97%;
  }

  .test-real-estate-home-row {
    height: 580px;
  }

  .test-reserved-banner {
    font-size: 18px;
    top: 1.8rem;
    left: -0.8rem;
    width: 148px;
    height: 2.6rem;
  }

  .test-banner-triangle {
    height: 10px;
    width: 13.8px;
    left: -0.88rem;
    top: 4.4rem;
  }

  .test-old-price::after {
    bottom: 13;
  }

  .test-properties-home-real-estate-card-column {
    height: 98%;
  }

  .test-properties-home-carousel-row {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .test-real-estate-home-property-card-link {
    width: 300px;
    position: relative;
    z-index: 1;
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .test-properties-home-no-image-container-general-recent {
    height: 60%;
    width: 100%;
    background-color: #efede7;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 0px 0px;
  }

  .test-properties-home-carousel-image {
    height: 60%;
    width: 100%;
  }

  .test-real-estate-content-row-recent {
    /* height: 90%; */
    height: 38%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
  }
}

@media (min-width: 1536px) {
  .test-video-column {
    height: 13rem;
    top: 50%;
  }

  .test-video-column.no-properties {
    top: 60%;
  }

  .test-video-text {
    width: 50rem;
    font-size: 1.9rem;
  }

  .test-mute-button {
    height: 3rem;
    width: 3rem;
  }

  .test-mute-icon {
    height: 2rem;
    width: 2rem;
  }

  .test-real-estate-home-button {
    height: 2.5rem;
    width: 10rem;
    font-size: 20px;
  }

  .test-real-estate-home-properties-carousel {
  }

  .test-property-column {
    height: 25%;
  }

  .test-real-estate-home-title {
    font-size: 40px;
  }

  .test-real-state-text-name-row {
    font-size: 28px;
  }

  .test-real-state-text-location-row {
    font-size: 20px;
    width: 90%;
  }

  .test-real-state-text-type-row {
    font-size: 39px;
  }

  .test-real-state-text-price-row {
    margin-bottom: 0;
    font-size: 30px;
  }

  .test-properties-home-carousel-container {
    height: 60%;
    width: 70%;
  }

  .test-properties-home-real-estate-card-row {
    position: relative;
    height: 100%;
  }

  .test-real-estate-home-row {
    height: 600px;
  }

  .test-reserved-banner {
    font-size: 19px;
    top: 1.6rem;
    left: -0.8rem;
    width: 150px;
    height: 2.7rem;
  }

  .test-banner-triangle {
    height: 10px;
    width: 13.8px;
    left: -0.88rem;
    top: 4.3rem;
  }

  .test-old-price::after {
    bottom: 15;
  }

  .test-properties-home-real-estate-card-column {
    height: 100%;
  }

  .test-properties-home-carousel-row {
    height: 100%;
    width: 100%;
  }

  .test-real-estate-home-property-card-link {
    width: 300px;
  }

  .test-properties-home-no-image-container-general-recent {
    height: 60%;
    width: 100%;
  }

  .test-properties-home-carousel-image {
    height: 60%;
    width: 100%;
  }

  .test-real-estate-content-row-recent {
    height: 38%;
    width: 90%;
  }
}
