@font-face {
  font-family: "BDSans-Regular"; /*Can be any text*/
  src: url("../../fonts/BDSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Higuen-Serif";
  src: url("../../fonts/Higuen-Serif.otf");
}

@font-face {
  font-family: "CormorantGaramond-Medium";
  src: url("../../fonts/CormorantGaramond-Medium.ttf") format("truetype");
}

.home-title {
  width: 100%;
  height: 100%;
  position: relative;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  outline: none;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sub-content {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* 
h1 {
  font-size: 28px;
  letter-spacing: 0.2cm;
  font-weight: lighter;
  margin-top: 0;
  margin-bottom: 30px;
  font-family: "BDSans-Regular";
  color: #efede7;
} */

h2 {
  font-size: 120px;
  margin-top: 0;
  margin-bottom: 50px;
  font-weight: lighter;
  font-family: "Higuen-Serif";
  color: #c28e2b;
}

.content-text {
  font-size: 34px;
  letter-spacing: 0.2cm;
  margin: 0;
  font-family: "CormorantGaramond-Medium";
  color: #efede7;
}

.square {
  position: relative;
  width: 400px;
  height: 210px;
  border: 1.5px solid #c28e2b;
  border-radius: 10px;
}

.inner-square {
  position: absolute;
  top: 42%;
  left: 50%;
  border: 2px solid #c28e2b;
  transform: translate(-50%, 10%);
  width: 280px;
  height: 150px;
  z-index: 1;
}

.square-text {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 70%);
  font-size: 24px;
  margin: 0;
  font-family: "BDSans-Regular";
  font-weight: 500;
  color: #efede7;
}

.inner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
