.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup {
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  height: 100px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup p {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #333;
}

.popup-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.popup-text {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: white;
}

.confirmation-popup-image {
  height: 68px;
  width: 68px;
}

.confirmation-class-modal {
  height: 120px;
  width: 390px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 18px;
  scroll-snap-align: start;
  background-color: #013469;
  /* border: 1px solid #0f54f0; */
  border-radius: 13px;
  flex-shrink: 0;
  margin-bottom: 10px;
  box-shadow: 0px 0px 100px 10px rgba(0, 0, 0, 0.5);
}
