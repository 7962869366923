.carousel {
  position: relative;
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-container {
  position: relative;
  width: 100%;
  margin-top: 80px;
  overflow: hidden;
}

.carousel-image {
  width: 1200px;
  height: 700px;
}

.no-image-container {
  width: 900px;
  height: 600px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.no-image-icon {
  width: 100px;
}

.image-number {
  position: absolute;
  bottom: 10px;
  right: 10px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: #013469;
  color: #c28e2b;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  /* font-weight: bold; */
  font-weight: 600;
}

.expand-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #013469;
  cursor: pointer;
  font-size: 30px;
}

.arrow {
  position: absolute;
  bottom: 40%;
  transform: translateY(-50%);
  /* background-color: rgba(255, 255, 255, 0.5); */
  /* background-color: rgba(1, 52, 105, 0.5); */
  background-color: #013469;
  color: #c28e2b;
  border: none;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}

.scroll-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.preview-bar {
  /* flex-grow: 1; */
  width: 1000px;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  overflow-x: hidden;
  /* overflow-x: auto; */
}

.preview-image {
  width: 150px;
  height: 100px;
  margin: 0 10px;
  cursor: pointer;
}

.preview-image-inactive {
  opacity: 0.5;
}

/* Adiciona margem apenas à direita para todos os elementos, exceto o último */
.preview-image:not(:last-child) {
  margin-right: 10px;
}

/* Adiciona margem apenas à esquerda para todos os elementos, exceto o primeiro */
.preview-image:not(:first-child) {
  margin-left: 10px;
}

/* Remove a margem à esquerda do primeiro elemento */
.preview-image:first-child {
  margin-left: 0;
}

/* Remove a margem à direita do último elemento */
.preview-image:last-child {
  margin-right: 0;
}

.scroll-arrow {
  position: absolute;
  bottom: 1.5%;
  transform: translateY(-50%);
  background-color: #013469;
  color: #c28e2b;
  border: none;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
}

.page-reserved-banner {
  position: absolute;
  font-size: 22px;
  font-family: "Open Sans", sans-serif;
  top: 30%;
  left: -10%;
  width: 38.2%;
  height: 7%;
  color: white;
  text-align: center;
  justify-content: center;
  line-height: 2; /* Altura da faixa de texto */
  font-weight: bold;
  transform: rotate(-45deg); /* Roda a faixa */
  transform-origin: left top; /* Define o ponto de rotação */
  z-index: 1; /* Garante que a faixa esteja acima da imagem */
  clip-path: polygon(21.8% 0, 64.5% 0, 75.5% 100%, 8.5% 100%);
}

.page-reserved-banner.reserved {
  background-color: #c28e2b;
}

.page-reserved-banner.sold {
  background-color: #ff0000;
}

.media-buttons-container {
  height: 60px;
  width: 100%;
}

.media-buttons {
  height: 40px;
  margin-bottom: 10px;
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.media-buttons button {
  flex-grow: 1;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  font: inherit;
  outline: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  transition: color 0.3s ease, border-bottom-color 0.3s ease;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 20px;
}

.media-buttons button:hover {
  color: #c28e2b;
  border-bottom: 1px solid #c28e2b;
}

.media-button.active {
  color: #c28e2b;
  border-bottom: 1px solid #c28e2b;
}

.media-buttons-image {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  border-color: transparent;
}

.property-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.property-page-container {
  width: 100%;
  position: relative;
  /* background: linear-gradient(90deg, #000000, #3533cd); */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #000000, #3533cd);
  overflow-y: hidden;
}

.property-page-container.loading {
  height: 100%;
}

.property-page-container.content {
  height: 2150px;
}

.real-estate-property-page-container {
  width: 70%;
}

.property-page-name {
  margin-top: 0px;
  margin-bottom: 0;
  height: auto;
  width: 500px;
  font-size: 40px;
  color: #c28e2b;
  font-family: "BDSans-Regular";
  font-weight: 500;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.property-page-location {
  margin-top: 10px;
  margin-bottom: 0;
  width: 500px;
  font-size: 30px;
  color: #efede7;
  font-family: "Open Sans", sans-serif;
  font-weight: 200;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.property-page-row {
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dashboard-property-page-title-container {
  margin-top: 50px;
  margin-left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}

.dashboard-property-page-header-button {
  height: 40px;
  width: 200px;
  font-size: 16px;
  border-radius: 8px;
  border: 2px solid red;
  background-color: rgb(234, 128, 128);
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
}

.dashboard-property-page-header-button:hover {
  background-color: rgb(191, 88, 88);
}
