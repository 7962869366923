.login-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-square {
  position: relative;
  width: 650px;
  height: 700px;
  border: 1px solid transparent;
  border-radius: 20px;
  background-color: #011932;
  box-shadow: 0px 0px 100px 10px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-input-container {
  position: relative;
  width: 70%;
}

.login-input {
  box-sizing: border-box;
  border: 2px solid #c28e2b;
  border-radius: 10px;
  width: 70%;
  height: 42px;
  padding-left: 10px;
  font-size: 16px;
  margin-bottom: 50px;
}

.login-input-password {
  box-sizing: border-box;
  border: 2px solid #c28e2b;
  border-radius: 10px;
  width: 100%;
  height: 42px;
  padding-left: 10px;
  font-size: 16px;
  margin-bottom: 50px;
}

.password-toggle {
  position: absolute;
  right: 20px;
  top: 23%;
  transform: translateY(-50%);
  cursor: pointer;
}

.login-eye {
  width: 25px;
}

.login-button {
  box-sizing: border-box;
  border: 0.5px solid transparent;
  border-radius: 10px;
  width: 30%;
  height: 42px;
  padding-left: 10px;
  font-size: 16px;
  background-color: #c28e2b;
  font-family: "BDSans-Regular";
  cursor: pointer;
}

.login-button.not-valid {
  box-sizing: border-box;
  border: 0.5px solid transparent;
  border-radius: 10px;
  width: 30%;
  height: 42px;
  padding-left: 10px;
  font-size: 16px;
  background-color: #7c7c7c;
  color: black;
  font-family: "BDSans-Regular";
  cursor: default;
}

.login-button.not-valid:hover {
  background-color: #7c7c7c;
}

.login-button:hover {
  background-color: #ffa600;
}

.add-button-cancel {
  box-sizing: border-box;
  border: 0.5px solid transparent;
  border-radius: 10px;
  width: 30%;
  height: 42px;
  padding-left: 10px;
  font-size: 16px;
  background: #ffffff;
  color: #0f0f0f;
  font-family: "BDSans-Regular";
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.add-button-cancel:hover {
  background: #c28e2b;
}

.login-image {
  height: 30%;
  /* width: 100%; */
  object-fit: contain;
  margin-bottom: 80px;
}

.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form--submit-loading {
  position: relative;
}

.form--submit-loading::after {
  content: "";
  position: absolute;
  top: 20%;
  left: 45%;
  width: 25px;
  height: 25px;
  border: 4px solid #011932;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
