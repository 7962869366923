@font-face {
  font-family: "BDSans-Regular"; /*Can be any text*/
  src: url("../../fonts/BDSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "CormorantGaramond-Medium";
  src: url("../../fonts/CormorantGaramond-Medium.ttf") format("truetype");
}

.navbar-teste {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  transition: top 0.5s ease;
  z-index: 1000;
}

.hidden {
  top: -100px; /* Adjust this value based on your navbar height */
}

.real-estate-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background: linear-gradient(90deg, #000000, #3533cd); */
  background-color: #011932;
  overflow-y: hidden;
}

.real-estate-container.loading {
  height: 100%;
}

.real-estate-container.oneLine {
  height: 1150px;
}

.real-estate-container.twoLines {
  height: 1650px;
}

.real-estate-container.threeLines {
  height: 2150px;
}

.real-estate-container.loading {
  height: 100%;
}

.real-estate-content {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 50px;
}

.real-estate-title {
  font-size: 62px;
  margin-top: 100px;
  margin-bottom: 50px;
  font-weight: lighter;
  font-family: "Higuen-Serif";
  color: #c28e2b;
}

/* .real-estate-column {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
} */

.real-estate-row-images {
  position: absolute;
  width: 100%;
  height: 100%;
  /* top: 100; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.real-estate-column-images {
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.real-estate-image {
  height: 280px;
  width: 100%;
  object-fit: cover;
  /* margin-top: 100px; */
}

.marker-real-estate-image {
  height: 100px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  /* margin-top: 100px; */
}

.marker-no-image-container-general {
  height: 100px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  border: 1px solid black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* margin-top: 100px; */
}

.marker-no-image-icon-general {
  width: 60px;
}

.marker {
  position: relative;
  z-index: 1;
}

.blue-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-53%, -53%);
  width: 18px;
  height: 18px;
  background-color: #1e63b5;
  border-radius: 50%;
}

.white-circle {
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.active-marker .blue-circle {
  background-color: #c28e2b; /* Azul alterado */
}

.marker-square {
  height: 210px;
  width: 180px;
  font-size: 10px;
  position: absolute;
  bottom: 62px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 8px;
  border-radius: 15px;
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.1);
}

.marker-triangle {
  height: 5px;
  width: 20px;
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 8px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
}

.marker-title {
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 15px;
  color: black;
}

.marker-price {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 20px;
  color: black;
  font-family: "CormorantGaramond-Medium";
}

.marker-old-price {
  position: relative;
  color: #c28e2b;
}

.marker-old-price::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 8;
  width: 100%;
  height: 2px;
  background-color: #c28e2b;
}

.marker-button {
  padding-left: 10px;
  padding-right: 10px;
  height: 25px;
  width: 88%;
  margin-top: 15px;
  font-size: 13px;
  border-color: transparent;
  background-color: #c28e2b;
  color: white;
  border-radius: 4px;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}

.real-state-text-location {
  margin-top: 30px;
  margin-bottom: 0;
  font-size: 28px;
  color: #efede7;
  font-family: "BDSans-Regular";
}

.real-state-text-type {
  margin-top: 15px;
  margin-bottom: 0;
  font-size: 39px;
  color: #c28e2b;
  font-family: "CormorantGaramond-Medium";
}

.real-state-text-price {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 34px;
  color: #efede7;
  font-family: "CormorantGaramond-Medium";
}
