/* .real-estate-content-container {
  width: 100%;
  height: 8cm;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
} */

.dashboard-real-estate-container {
  width: 100%;
  position: relative;
  /* background: linear-gradient(90deg, #000000, #3533cd); */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #000000, #3533cd);
  overflow-y: hidden;
}

.dashboard-title-container {
  width: 94%;
  margin-left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.dashboard-title-container.loading {
  top: 0;
}

.dashboard-title-buttons-container {
  width: 27%;
  margin-left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.dashboard-real-estate-header-button {
  height: 40px;
  width: 200px;
  font-size: 16px;
  border-radius: 8px;
  border: none;
  text-decoration: none;
  border: 2px solid #c28e2b;
  background-color: #c9b184;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
}

.dashboard-real-estate-header-button:hover {
  background-color: #b89657;
}

.dashboard-real-estate-title {
  left: 0;
  font-size: 50px;
  margin-top: 40px;
  margin-bottom: 30px;
  font-weight: lighter;
  font-family: "Higuen-Serif";
  color: #c28e2b;
}

.dashboard-real-estate-container.popup-open {
  height: 100%;
}

.dashboard-real-estate-container.map {
  height: 1330px;
}

.dashboard-real-estate-container.threeColumns-oneLine {
  height: 959px;
}

.dashboard-real-estate-container.threeColumns-twoLines {
  height: 1450px;
}

.dashboard-real-estate-container.threeColumns-threeLines {
  height: 1950px;
}

.dashboard-real-estate-container.oneRow {
  height: 959px;
}

.dashboard-real-estate-container.twoRows {
  height: 1100px;
}

.dashboard-real-estate-container.threeRows {
  height: 1430px;
}

.dashboard-real-estate-container.fourRows {
  height: 1780px;
}

.dashboard-real-estate-container.fiveRows {
  height: 2120px;
}

.dashboard-real-estate-container.sixRows {
  height: 2460px;
}

.dashboard-real-estate-container.sevenRows {
  height: 2800px;
}

.dashboard-real-estate-container.eightRows {
  height: 3140px;
}

.dashboard-real-estate-container.nineRows {
  height: 3550px;
}

.dashboard-real-estate-container.loading {
  height: 100%;
}

.real-estate-page-container {
  width: 100%;
}

.filters-bar-container {
  width: 94%;
  margin-left: 50px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.filters-bar-content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* display: flex;
  flex-direction: row;
  justify-content: space-evenly; */
}

.search-container {
  position: relative;
  display: inline-block;
  /* position: absolute;
  z-index: 10; 
  /* display: flex;
  justify-content: center; */
  height: 36px;
  border: 1px solid #000000;
  border-radius: 10px;
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
}

.search-input {
  height: 36px;
  width: 400px;
  background-color: transparent;
  border: 0px solid transparent;
  padding-left: 15px;
  font-size: 15px;
}

.search-input:focus {
  outline: none;
}

.location-container {
  height: 36px;
  width: 200px;
  border-left: 1px solid black;
  border-right: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: start;
}

.form-location-container {
  height: 36px;
  width: 200px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 20px;
}

.add-form-location-container {
  height: 36px;
  width: 200px;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 20px;
}

.location-button {
  position: relative;
  height: 100%;
  width: 100%;
  padding-left: 15px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
}

.add-location-button {
  position: relative;
  height: 100%;
  width: 100%;
  padding-left: 15px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  color: white;
}

.location-checkbox-item {
  flex-shrink: 0;
  margin-right: 10px;
  accent-color: #c28e2b;
  height: 14px;
  width: 14px;
}

.search-container {
  height: 36px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: start;
}

.search-icon {
  height: 20px;
  right: 30;
  padding-right: 10px;
  padding-left: 10px;
}

.property-type-dropdown {
  width: 170px;
  height: 30px;
  background: white;
  border: 1px solid #000000;
  border-radius: 5px;
  color: black;
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
  padding-left: 10px;
  padding-right: 10px;
}

.property-type-dropdown option {
  background-color: white;
  color: black;
}

.properties-container-top {
  margin-left: 50px;
  width: 94%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.properties-container-top.menu-open {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filters-menu {
  width: 260px;
  /* background-color: #ffffff; */
  margin-top: 30px;
  z-index: 1;
  /* position: fixed; */
}

/* .filters-menu.map {
  position: absolute;
  height: 500px;
  width: 260px;
  background-color: #000000;
  margin-top: 30px;
  z-index: 1;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 10px 10px;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
} */

.filters-menu.threeColumns-oneLine {
  height: 450px;
}

.filters-menu.threeColumns-twoLines {
  height: 745px;
}

.filters-menu.threeColumns-threeLines {
  height: 850px;
}

.filters-menu.oneRow {
  height: 500px;
}

.filters-menu.twoRows {
  height: 620px;
}

.filters-menu.threeRows {
  height: 768px;
}

.filters-menu.fourRows {
  height: 758px;
}

.filters-menu.fiveRows {
  height: 758px;
}

.filters-menu.sixRows {
  height: 888px;
}

.filters-menu.sevenRows {
  height: 872px;
}

.filters-menu.eightRows {
  height: 862px;
}

.filters-menu.nineRows {
  height: 833px;
}

.filters-menu.fixed {
  margin-top: 0px;
  position: fixed;
  top: 0;
}

.filters-menu.down {
  margin-top: 650px;
}

.filters-menu.fiveRows-down {
  margin-top: 680px;
}

.filters-menu.sixRows-down {
  /* margin-top: 680px; */
  margin-top: 1125px;
}

.filters-menu.sevenRows-down {
  /* margin-top: 680px; */
  margin-top: 1480px;
}

.filters-menu.eightRows-down {
  /* margin-top: 680px; */
  margin-top: 1830px;
}

.filters-menu.nineRows-down {
  /* margin-top: 680px; */
  margin-top: 2200px;
}

.filters-menu-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.filters-menu-content::-webkit-scrollbar {
  width: 7px; /* largura do scrollbar */
}

.filters-menu-content::-webkit-scrollbar-track {
  background: transparent; /* cor de fundo do scrollbar */
}

.filters-menu-content::-webkit-scrollbar-thumb {
  background-color: #888; /* cor do scrollbar */
  border-radius: 6px; /* borda do scrollbar */
}

.filters-menu-content::-webkit-scrollbar-thumb:hover {
  background: #555; /* cor do scrollbar ao passar o mouse */
}

.filters-menu-title {
  color: #ffffff;
  font-size: 23px;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  font-weight: 600;
  margin-top: 0px;
  width: 95%;
  /* letter-spacing: 0cap; */
}

.filters-menu-content.fixed {
  margin-top: 25px;
}

.filters-menu-options-container {
  width: 95%;
}

.filters-line-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.filters-line {
  border-top: 0.5px solid gray;
}

.filters-line.sort {
  align-items: center;
  width: 85%;
  border-top: 0.5px solid gray;
}

.dropdown-option {
  /* border-top: 0.5px solid gray; */
  border-top: none;
  border-bottom: none;
  border-left: none;
  border-right: none;
  padding: 10px;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  text-align: left;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .dropdown-option.open {
  border-bottom: 0.5px solid gray; 
} */

.properties-container {
  width: 100%;
  margin-bottom: 50px;
  margin-top: 30px;
  text-decoration: none;
  color: black;
}

.properties-container.threeColumns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 60px;
}

.properties-container.threeRows {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 60px;
}

.properties-container.menu-open {
  width: 82%;
}

.properties-container.fixed {
  margin-left: 290px;
}

.properties-error-container {
  height: 100%;
  width: 100%;
}

.properties-error {
  height: 100%;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: red;
}

.properties-button-cancel {
  width: 99px;
  height: 36px;
  background: #ffffff;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  border: 0.5px solid #0f54f0;
  font-size: 14px;
  color: #0f0f0f;
}

.properties-button-cancel:hover {
  background: #0f54f0;
  color: white;
}

.properties-button-confirm {
  background: #0f54f0;
  border-radius: 10px;
  width: 99px;
  height: 36px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.properties-button-confirm:hover {
  background: #0f84f0;
}

.search-bar-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  margin-left: 80px;
  /* transform: translateX(-50%); */
}

.pagination-button {
  width: 30px;
  height: 30px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: black;
  /* background: none; */
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 50%;
}

.pagination-button.active-page {
  /* background: #c28e2b; */
  color: white;
  background-color: #c28e2b;
}

.pagination-button:hover {
  /* background: #c28e2b; */
  color: white;
  background-color: #c28e2b;
}

.pagination-ellipsis {
  margin-right: 5px;
  margin-left: 5px;
  color: #c28e2b;
}

.input-container {
  height: 40px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-container.square {
  width: 170px;
}

.input-container.price {
  width: 190px;
}

.input-container::after {
  font-size: 18px;
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
}

.input-container.square::after {
  content: "m²";
}

.input-container.price::after {
  content: "€";
}

.input-text {
  border-color: transparent;
  height: 40px;
  width: 100%;
  padding: 8px;
  text-align: center;
  font-size: 17px;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  color: #ffffff;
  border-radius: 3px;
  border: 1px solid gray;
  background-color: transparent;
  text-align: start;
}

.price-input-container {
  width: 150px;
  position: relative;
  display: inline-block;
}

.currency {
  position: absolute;
  color: gray;
  font-size: 17px;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  top: 25%;
  right: 6%;
}

.currency.error-input {
  color: red;
}

.currency-square {
  position: absolute;
  color: gray;
  font-size: 15px;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  top: 18%;
  right: 6%;
}

.currency-square.error-input {
  color: red;
}

.button-left,
.button-right {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 20px;
}

.button-left {
  margin-right: -1px;
}

.button-right {
  margin-left: -1px;
}

.real-estate-image-wrapper {
  height: 280px;
  width: 100%;
  position: relative;
  display: inline-block;
}

.real-estate-image-wrapper-row {
  height: 100%;
  width: 30%;
  position: relative;
  display: inline-block;
}

/* .reserved-banner {
  position: absolute;
  font-size: 22px;
  font-family: "Open Sans", sans-serif;
  top: 55%;
  left: -10%;
  width: 65%;
  height: 15%;
  color: white;
  text-align: center;
  justify-content: center;
  line-height: 2; 
  font-weight: bold;
  transform: rotate(-45deg); 
  transform-origin: left top; 
  z-index: 1;
  clip-path: polygon(21.8% 0, 68% 0, 81% 100%, 8.5% 100%);
} */

.reserved-banner {
  position: absolute;
  font-size: 19px;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  top: 8%;
  left: -2.5%;
  width: 150px;
  height: 13%;
  color: white;
  text-align: center;
  justify-content: center;
  line-height: 2;
  font-weight: bold;
  z-index: 1;
  border-radius: 3px 7px 7px 0;
  /* transform: rotate(-45deg);
  transform-origin: left top;  */
  /* clip-path: polygon(21.8% 0, 68% 0, 81% 100%, 8.5% 100%); */
}

.banner-triangle {
  position: absolute;
  height: 10px;
  width: 13.8px;
  z-index: 1;
  left: -2.8%;
  top: 21%;
  clip-path: polygon(100% 0%, 0% 0%, 100% 100%);
}

.reserved-banner.reserved {
  background-color: #c28e2b;
}

.reserved-banner.sold {
  background-color: #ff0000;
}

.banner-triangle.reserved {
  background-color: #6e5019;
}

.banner-triangle.sold {
  background-color: #671111;
}

.property-card-link {
  position: relative;
  z-index: 1;
  text-decoration: none;
  color: inherit;
}

.row-property-card-link {
  height: 280px;
  text-decoration: none;
  color: inherit;
}

/* .real-estate-column-images {
  height: 100px;
  width: 300px;
} */

.real-estate-card-row {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  /* background-color: #ffffff; */
}

.real-estate-content-row {
  height: 80%;
  align-items: start;
  margin-left: 20px;
}

.real-state-text-name-row {
  margin-bottom: 5px;
  font-size: 28px;
  color: #efede7;
  font-family: "BDSans-Regular";
}

.real-state-text-location-row {
  margin-bottom: 0px;
  font-size: 28px;
  color: #efede7;
  font-family: "BDSans-Regular";
}

.real-state-text-type-row {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 39px;
  color: #c28e2b;
  font-family: "CormorantGaramond-Medium";
}

.real-state-text-price-row {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 34px;
  color: #efede7;
  font-family: "CormorantGaramond-Medium";
}

.no-image-container-general {
  width: 100%;
  height: 280px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.no-image-icon-general {
  width: 100px;
}

.old-price {
  position: relative;
  color: #c28e2b;
}

.old-price::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 16;
  width: 100%;
  height: 2px;
  background-color: #c28e2b;
}

.dropdown-button {
  position: relative;
  width: 200px;
  padding: 8px 15px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  text-align: start;
}

.dropdown-menu {
  margin-top: 0px;
  /* position: absolute; */
  /* background-color: #f9f9f9; */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  /* width: 200px; */
  width: 95%;
  /* height: 150px; */
  z-index: 1;
  /* border-bottom: 0.5px solid gray; */
  padding-bottom: 12px;
}

.dropdown-price-menu {
  margin-top: 10px;
  /* position: absolute; */
  /* background-color: #f9f9f9; */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  /* width: 200px; */
  height: 150px;
  width: 95%;
  /* height: 150px; */
  z-index: 1;
  /* border-bottom: 0.5px solid gray; */
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

/* .checkbox-item {
  width: 180px;
  padding: 5px 10px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.checkbox-item label {
  cursor: pointer;
} */

.dropdown-header {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

.action-button {
  padding: 4px 8px;
  border: 1px solid #ccc;
  background-color: #f8f8f8;
  cursor: pointer;
}

.checkbox-wrapper {
  display: flex;
  align-items: center; /* Alinha verticalmente o texto e o checkbox */
  width: 160px;
  padding: 5px 10px;
}

.checkbox-wrapper:not(:last-child) {
  position: relative; /* Para posicionamento correto da borda */
  padding-bottom: 10px; /* Espaçamento abaixo do checkbox para separação visual */
  margin-bottom: 0px;
}

.checkbox-wrapper:not(:last-child)::after {
  content: ""; /* Cria um pseudo-elemento para a borda */
  position: absolute;
  left: 15px; /* Padding horizontal */
  right: 15px; /* Padding horizontal */
  bottom: 0; /* Posiciona na parte inferior do elemento */
  /* border-bottom: 1px solid #ccc; */
  width: calc(100% - 30px); /* Largura ajustada com base no padding */
}

.checkbox-item {
  flex-shrink: 0;
  margin-right: 10px;
  accent-color: #c28e2b;
  height: 18px;
  width: 18px;
}

/* .checkbox-item {
  width: 18px;
  height: 18px;
  background-color: white;
  border-radius: 20%;
  vertical-align: middle;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  accent-color: #c28e2b;
}

.checkbox-item:checked {
  background-color: #c28e2b;
} */

.checkbox-text {
  color: white;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  flex-grow: 1; /* Permite que o texto ocupe o espaço restante */
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  font-size: 17px;
}

.dropdown-arrow {
  color: black;
  background-color: transparent;
  /* position: absolute; */
  /* top: 10%; */
  /* right: 10px; */
  /* transform: translateY(-50%); */
  transition: transform 0.3s ease;
}

.dropdown-arrow.open {
  transform: translateY(-50%) rotate(180deg);
}

.arrow-down-icon {
  padding-right: 5px;
  width: 14px;
  height: 8px;
}

.arrow-down-icon.close {
  padding-right: 5px;
  width: 14px;
  height: 14px;
}

.filters-button {
  position: relative;
  width: 182px;
  border: 0px;
  /* background-color: #c28e2b; */
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  border-radius: 3px;
  align-items: center;
}

.filters-button-map {
  position: relative;
  border: 0px;
  /* background-color: #c28e2b; */
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  font-size: 20px;
  color: #c28e2b;
  display: flex;
  justify-content: center;
  border-radius: 3px;
  align-items: center;
}

.filters-button-sort {
  position: relative;
  border: 0px;
  /* background-color: #c28e2b; */
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  border-radius: 3px;
  align-items: center;
}

.filters-button-elements {
  border: 0px;
  /* background-color: #c28e2b; */
  background-color: transparent;
  cursor: pointer;
}

/* .filters-button:hover {
  background-color: #ac7d27;
} */

.filters-text {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px;
  /* font-family: "SF Pro Display", sans-serif; */
}

.filters-text-sort {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 12px;
  /* font-family: "SF Pro Display", sans-serif; */
}

/* .arrow-down-icon {
  padding-right: 5px;
  width: 20px;
  height: 20px;
} */

.filters-icon {
  width: 20px;
  height: 20px;
}

.filters-grid-icon {
  width: 25px;
  height: 25px;
}

.filters-menu-final-row {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.filters-clear-button {
  border-color: transparent;
  height: 40px;
  width: 121px;
  padding: 8px;
  text-align: center;
  font-size: 17px;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  color: #c28e2b;
  border-radius: 3px;
  border: 1px solid #c28e2b;
  background-color: transparent;
  text-align: start;
  cursor: pointer;
}

.header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sort-dropdown {
  position: absolute;
  top: calc(100% + 5px); /* Espaçamento entre o botão e o dropdown */
  right: 105px;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  z-index: 100;
}

/* Estilo da lista de opções */
.sort-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sort-dropdown ul li {
  margin-top: 0px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
}

/* Estilo de hover para as opções */
.sort-dropdown ul li:hover {
  background-color: #f0f0f0;
}

.sort-dropdown ul li.active {
  color: #c28e2b;
}

.location-dropdown {
  max-height: 300px;
  width: 350px;
  position: absolute;
  top: calc(100% + 5px);
  left: 400px;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  z-index: 100;
  overflow-y: auto;
}

.location-dropdown::-webkit-scrollbar {
  width: 7px; /* largura do scrollbar */
}

.location-dropdown::-webkit-scrollbar-track {
  background: transparent; /* cor de fundo do scrollbar */
}

.location-dropdown::-webkit-scrollbar-thumb {
  background-color: #888; /* cor do scrollbar */
  border-radius: 6px; /* borda do scrollbar */
}

.location-dropdown::-webkit-scrollbar-thumb:hover {
  background: #555; /* cor do scrollbar ao passar o mouse */
}

.location-dropdown-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/* Estilo da lista de opções */
.location-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.location-dropdown ul li {
  margin-top: 0px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
}

/* Estilo de hover para as opções */
.location-dropdown ul li:hover {
  background-color: #f0f0f0;
}

.location-dropdown ul li.active {
  color: #c28e2b;
}

.form-location-dropdown {
  max-height: 300px;
  width: 100%;
  left: 20px;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  z-index: 100;
  overflow-y: auto;
  margin-bottom: 20px;
}

.form-location-dropdown::-webkit-scrollbar {
  width: 7px; /* largura do scrollbar */
}

.form-location-dropdown::-webkit-scrollbar-track {
  background: transparent; /* cor de fundo do scrollbar */
}

.form-location-dropdown::-webkit-scrollbar-thumb {
  background-color: #888; /* cor do scrollbar */
  border-radius: 6px; /* borda do scrollbar */
}

.form-location-dropdown::-webkit-scrollbar-thumb:hover {
  background: #555; /* cor do scrollbar ao passar o mouse */
}

.form-location-dropdown-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/* Estilo da lista de opções */
.form-location-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.form-location-dropdown ul li {
  margin-top: 0px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
}

/* Estilo de hover para as opções */
.form-location-dropdown ul li:hover {
  background-color: #f0f0f0;
}

.form-location-dropdown ul li.active {
  color: #c28e2b;
}

.sub-checkboxes li {
  padding-right: 0px;
}

.map-window {
  height: 900px;
  width: 100%;
  margin-top: 15px;
  position: relative;
  z-index: 1;
}

.map-window.menu-open {
  width: 82%;
}

.map-window.fixed {
  margin-left: 290px;
  color: #023164;
}

.draw-shape-button {
  height: 50px;
  width: 150px;
  position: absolute;
  z-index: 100;
  top: 60;
  left: 30;
  color: #023164;
  background-color: transparent;
  font-size: 20px;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  border: 2px solid #023164;
  border-radius: 8px;
  cursor: pointer;
  z-index: 10000;
}

.current-location-button {
  height: 40px;
  width: 40px;
  position: absolute;
  z-index: 100;
  bottom: 90;
  right: 10;
  background-color: white;
  font-size: 20px;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  border-radius: 0px;
  border-color: transparent;
  cursor: pointer;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}

.current-location-icon {
  height: 20px;
}

.draw-shape-button.clean {
  color: red;
  border: 2px solid red;
}

.search-area-popup {
  height: 50px;
  width: 400px;
  position: absolute;
  z-index: 100;
  bottom: 60;
  left: 50%;
  right: 50%;
  color: white;
  background-color: #023164;
  font-size: 18px;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.search-area-popup-error {
  height: 50px;
  width: 400px;
  position: absolute;
  z-index: 100;
  bottom: 60;
  left: 50%;
  right: 50%;
  color: white;
  background-color: red;
  font-size: 18px;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.form-group {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
}

.form-group-lat-lng {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

.form-group:first-child {
  margin-top: 5px;
}

.form-group:last-child {
  margin-bottom: 5px;
}

.form-group-input {
  width: 100%;
  height: 40px;
  padding-left: 10px;
  font-size: 15px;
}

.form-group-input.error-input {
  border: 1px solid red;
}

.form-group-textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  font-size: 15px;
  resize: none;
}

.form-group-textarea.error-input {
  border: 1px solid red;
  color: red;
}

.form-group-textarea.error-input::placeholder {
  color: red;
}

.form-group-input.error-input {
  border: 1px solid red;
  color: red;
}

.form-group-input.error-input::placeholder {
  color: red;
}

/* .form-group-textarea.error-input:focus,
.form-group-textarea.error-input:focus::placeholder {
  color: red;
  border: 1px solid red;
} */

.image-upload-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tabs {
  display: flex;
  gap: 10px;
}

.tab-button {
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
}

.tab-button.active {
  color: #c28e2b;
  font-weight: bold;
  border-bottom: 2px solid #c28e2b;
}
/* Button for uploading images */
.upload-button {
  background: #007bff;
  width: 150px;
  height: 35px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.upload-button input {
  display: none;
}

/* Container for image grid */
.image-grid-container {
  background-color: #a8a8a8;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  height: 500px;
  max-height: 500px;
  overflow-y: auto;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
}

/* Styles when dragging files over the container */
.image-grid-container.dragging {
  background-color: #3b3a3ae0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

.image-grid-container.no-items {
  height: 500px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* Each image item in the grid */
.image-grid-item {
  position: relative;
  cursor: pointer;
}

.image-grid-item img {
  width: 100%;
  height: 260px;
  border-radius: 5px;
  display: block;
}

.image-grid-item video {
  width: 100%;
  height: 260px;
  border-radius: 5px;
  display: block;
}

/* Button to remove an image */
.remove-image-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(255, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.add-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-modal-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 800px;
  height: 500px;
  text-align: center;
}

.add-modal-content img {
  width: 800px;
  height: 500px;
  border-radius: 10px;
}

/* Close button */
.add-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(255, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
}

/* Navigation buttons */
.add-modal-prev,
.add-modal-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  z-index: 1;
}

.add-modal-prev {
  left: 10px;
}

.add-modal-next {
  right: 10px;
}

.warning-message {
  color: red;
  margin-bottom: 10px;
}

.add-popup-buttons-row {
  margin-top: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.form-submit-error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  display: block;
}
