@font-face {
  font-family: "CormorantGaramond-Medium";
  src: url("../../fonts/CormorantGaramond-Medium.ttf") format("truetype");
}

.contacts-container {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #011932;
}

.contacts-square {
  border: 3px solid #c28e2b;
  width: 600px;
  height: 400px;
}

.contacts-square-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contacts-content {
  position: absolute;
  width: 100%;
  height: 88%;
  top: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.contacts-content-text {
  top: 0;
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  align-items: center;
}

.contacts-text {
  font-size: 24px;
  font-family: "CormorantGaramond-Medium";
  font-weight: 500;
  color: #efede7;
  margin-top: 0;
  margin-bottom: 5px;
}

.contacts-sub-text {
  font-size: 20px;
  font-family: "CormorantGaramond-Medium";
  font-weight: 500;
  color: #efede7;
  margin-top: 0;
  margin-bottom: 40px;
}

.contacts-h2 {
  font-size: 120px;
  margin: 0;
  font-weight: lighter;
  font-family: "Higuen-Serif";
  color: #c28e2b;
}
