.app-container-contactos {
  height: 100%;
  width: 100%;
  background-color: #011932;
}

.test-contacts-content {
  position: absolute;
  width: 100%;
  height: 88%;
  top: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

/* .test-contacts-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  z-index: 1;
  margin-bottom: 50px;
}

.test-contacts-content {
  width: 100%;
  height: 41rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
} */

.test-contacts-square {
  border: 3px solid #c28e2b;
  /* height: 40vh;
  width: 30vw; */
}

.test-social-media-bar {
  z-index: 10;
}

.test-h1 {
  /* font-size: 28px; */
  letter-spacing: 0.2cm;
  font-weight: lighter;
  margin-top: 0;
  /* margin-bottom: 30px; */
  font-family: "BDSans-Regular";
  color: #efede7;
  text-align: center;
}

.test-contacts-text {
  /* font-size: 24px; */
  font-family: "CormorantGaramond-Medium";
  font-weight: 500;
  color: #efede7;
  margin-top: 0;
  /* margin-bottom: 5px; */
}

.test-contacts-sub-text {
  /* font-size: 20px; */
  font-family: "CormorantGaramond-Medium";
  font-weight: 500;
  color: #efede7;
  margin-top: 0;
  /* margin-bottom: 40px; */
}

.test-contacts-content-text-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.test-contacts-content-text {
  height: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 307px) {
  .test-contacts-square {
    height: 9rem;
    width: 18rem;
  }

  .test-h1 {
    font-size: 1.2rem;
  }

  .test-contacts-text {
    font-size: 1.1rem;
  }

  .test-contacts-sub-text {
    font-size: 1.1rem;
  }

  .test-social-media-bar {
    width: 14rem;
  }

  .test-mediabar-icon {
    height: 2.2rem;
    width: auto;
  }
}

@media (min-width: 307px) {
  .test-contacts-square {
    height: 9rem;
    width: 18rem;
  }

  .test-h1 {
    font-size: 1.2rem;
  }

  .test-contacts-text {
    font-size: 1.1rem;
  }

  .test-contacts-sub-text {
    font-size: 1.1rem;
  }

  .test-social-media-bar {
    width: 15rem;
  }

  .test-mediabar-icon {
    height: 2.3rem;
    width: auto;
  }
}

@media (min-width: 400px) {
  .test-h1 {
    font-size: 1.5rem;
  }

  .test-contacts-text {
    font-size: 1.2rem;
  }

  .test-contacts-sub-text {
    font-size: 1.2rem;
  }

  .test-social-media-bar {
    width: 16rem;
  }

  .test-mediabar-icon {
    height: 2.4rem;
    width: auto;
  }
}

@media (max-width: 475px) {
  .test-contacts-content {
    top: 150;
    height: 70%;
  }
}

@media (min-width: 475px) {
  .test-contacts-square {
    height: 13rem;
    width: 25rem;
  }

  .test-h1 {
    font-size: 1.7rem;
  }

  .test-contacts-text {
    font-size: 1.3rem;
  }

  .test-contacts-sub-text {
    font-size: 1.3rem;
  }

  .test-social-media-bar {
    width: 17rem;
  }

  .test-mediabar-icon {
    height: 2.5rem;
    width: auto;
  }
}

@media (min-width: 640px) {
  .test-contacts-square {
    height: 14rem;
    width: 26rem;
  }

  .test-h1 {
    font-size: 1.7rem;
  }

  .test-contacts-text {
    font-size: 1.3rem;
  }

  .test-contacts-sub-text {
    font-size: 1.3rem;
  }

  .test-social-media-bar {
    width: 18rem;
  }

  .test-mediabar-icon {
    height: 2.6rem;
    width: auto;
  }
}

@media (max-width: 640px) {
}

@media (min-width: 768px) {
  .test-contacts-square {
    height: 15rem;
    width: 27rem;
  }

  .test-h1 {
    font-size: 1.7rem;
  }

  .test-contacts-text {
    font-size: 1.3rem;
  }

  .test-contacts-sub-text {
    font-size: 1.3rem;
  }

  .test-social-media-bar {
    width: 19rem;
  }

  .test-mediabar-icon {
    height: 2.7rem;
    width: auto;
  }
}

@media (min-width: 1024px) {
  .test-contacts-square {
    height: 16rem;
    width: 28rem;
  }

  .test-h1 {
    font-size: 1.8rem;
  }

  .test-contacts-text {
    font-size: 1.3rem;
  }

  .test-contacts-sub-text {
    font-size: 1.3rem;
  }

  .test-social-media-bar {
    width: 20rem;
  }

  .test-mediabar-icon {
    height: 2.8rem;
    width: auto;
  }
}

@media (min-width: 1280px) {
  .test-contacts-square {
    height: 17rem;
    width: 29rem;
  }

  .test-h1 {
    font-size: 1.9rem;
  }

  .test-contacts-text {
    font-size: 1.4rem;
  }

  .test-contacts-sub-text {
    font-size: 1.3rem;
  }

  .test-social-media-bar {
    width: 21rem;
  }

  .test-mediabar-icon {
    height: 2.9rem;
    width: auto;
  }
}

@media (min-width: 1536px) {
  .test-contacts-square {
    height: 18rem;
    width: 30rem;
  }

  .test-h1 {
    font-size: 2rem;
  }

  .test-contacts-text {
    font-size: 1.5rem;
  }

  .test-contacts-sub-text {
    font-size: 1.3rem;
  }

  .test-social-media-bar {
    width: 22rem;
  }

  .test-mediabar-icon {
    height: 3rem;
    width: auto;
  }
}
