.sidebar {
  background-color: #013469;
  position: absolute;
  width: 96px;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: -2px 0 2px rgba(0, 0, 0, 0.2);
  transition: width 0.5s ease;
  position: fixed;
  border-radius: 0px 20px 20px 0px;
}

.sidebar-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar:hover {
  width: 222px;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
  border-radius: 0px 20px 20px 0px;
}

li {
  margin-top: 30px;
  list-style-type: none;
}

ul {
  padding-left: 0px;
}

.sidebar-item {
  height: 50px;
  /* font-family: "Montserrat", sans-serif; */
  font-weight: 400;
  font-size: 16px;
  color: #0f0f0f;
  cursor: pointer;
  list-style: none;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: start;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: justify-content 0.5s ease, width 0.5s 0.5s ease;
}

.sidebar-item:hover {
  background: rgba(190, 207, 246, 0.25);
}

.sidebar:hover .sidebar-item {
  overflow: visible;
}

.sidebar--icon--container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar--icon {
  margin-top: 20px;
  height: 75px;
  width: 75px;
  transition: transform 0.5s ease;
}

.sidebar-image {
  margin-right: 0px;
  height: 30px;
}

.item-container {
  display: grid;
  grid-template-columns: 38px auto;
  align-items: center;
  width: 100%;
  padding-left: 32px;
  transition: opacity 0.5s ease;
}

.close-item{
  text-decoration: none;
}

.close-item-container {
  display: grid;
  grid-template-columns: 38px auto;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  padding-left: 32px;
  transition: opacity 0.5s ease;
}

.close-item .item-container {
  grid-template-columns: 30px auto;
}

.icon-container {
  width: 50px;
}

.text-container {
  color: white;
  padding-left: 20px;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-container-active {
  color: #c28e2b;
  padding-left: 20px;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-container-leave {
  color: #ff0000;
  padding-left: 20px;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
