.real-estate-home-container {
  height: 2950px;
  background-color: #011932;
  overflow-y: auto;
}

.real-estate-home-container.no-properties {
  height: 100%;
}

.real-estate-home-container.no-highlight {
  height: 2350px;
}

.real-estate-home-container.no-promotion {
  height: 2150px;
}

.real-estate-home-container.no-promotion-no-highlight {
  height: 1550px;
}

.real-estate-home-content {
  height: 100%;
  width: 100%;
}

.real-estate-home-column {
  width: 100%;
  height: 100%;
  /* top: 100; */
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.pattern-bg:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f1f3f6;
  z-index: 0;
  opacity: 0.82;
}

.real-estate-home-row {
  position: relative;
  z-index: 1; /* Garante que o conteúdo esteja acima do fundo do padrão */
  background-image: url("https://cdn.vectorstock.com/i/2000v/15/57/abstract-blue-square-shape-overlay-background-vector-45431557.avif");
  height: 600px;
  width: 100%;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  overflow: hidden; /* Para esconder as bordas pseudo-elemento */
  background-position: center;
  background-size: cover;
}

.video-container {
  position: relative;
  width: 100%;
}

.video-container.no-properties {
  height: 100%;
}

.video-text {
  position: absolute;
  width: 900px;
  top: 50%;
  left: 25%;
  color: white;
  font-size: 35px;
  text-align: center;
  font-family: "BDSans-Regular";
  font-weight: 500;
}

.video-text.no-properties {
  top: 60%;
}

.real-estate-home-buttons-row {
  width: 350px;
  position: absolute;
  top: 70%;
  left: 39.5%;
  display: flex;
  flex-direction: row;
  /*! mudar para start quando acrescentar o botão vender */
  /* justify-content: space-between; */
  justify-content: center;
}

.real-estate-home-buttons-row {
  top: 78%;
}

.real-estate-home-button {
  height: 42px;
  width: 150px;
  background-color: #c28e2b;
  color: white;
  border-color: transparent;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.real-estate-home-button:hover {
  background-color: #aa781b;
}

.real-estate-home-video {
  height: 750px;
  width: 100%;
}

.real-estate-home-video.no-properties {
  height: 100%;
}

.mute-button {
  height: 50px;
  width: 50px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 1;
  background-color: #c28e2b;
  border-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.mute-icon {
  height: 30px;
  width: 30px;
}

.real-estate-home-row-container {
  width: 90%;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
}

.real-estate-home-title-container {
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.real-estate-home-title-highlight-container {
  margin-top: 50px;
  margin-bottom: 30px;
  margin-left: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.real-estate-home-title {
  font-size: 40px;
  font-weight: lighter;
  font-family: "Higuen-Serif";
  color: #c28e2b;
}

.real-estate-home-properties-row {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.real-estate-home-properties-row > :not(:last-child) {
  margin-right: 50px;
}

.real-estate-home-property-card-link {
  width: 300px;
  position: relative;
  z-index: 1;
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.real-estate-home-properties-carousel {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.properties-home-carousel-container {
  height: 360px;
  margin-top: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.properties-home-carousel-image {
  display: block;
  width: 700px;
  height: 320px;
}

.carousel-dots {
  margin-top: 40px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center; /* Alinha os itens ao centro */
}

.carousel-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carousel-dot.active {
  background-color: #c28e2b;
}

.properties-home-no-image-container-general {
  height: 320px;
  width: 700px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.properties-home-real-estate-card-row {
  height: 100%;
  width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.properties-home-row-property-card-link {
  height: 320px;
  text-decoration: none;
  color: inherit;
}

.real-estate-home-see-more {
  background-color: transparent;
  color: white;
  border-color: transparent;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  font-size: 15px;
  cursor: pointer;
  text-decoration: none;
}
