.dashboard-real-estate-add-container {
  background: linear-gradient(90deg, #000000, #3533cd);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dashboard-real-estate-add-column {
  width: 90%;
  height: 100%;
  /* top: 100; */
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-bottom: 50px;
}

.dashboard-add-container {
  width: 60%;
}

/* Customizing the toolbar buttons */
.ql-toolbar .ql-stroke {
  stroke: white !important;
}

.ql-toolbar .ql-fill {
  fill: white !important;
}

.ql-toolbar .ql-picker {
  color: white !important;
}

.ql-toolbar .ql-picker-options {
  background-color: #333; /* Optional: Dark background for dropdowns */
}

.ql-toolbar .ql-picker-item {
  color: white !important;
}

/* Customizing the placeholder */
.ql-editor::before {
  color: white !important;
  opacity: 0.5; /* Optional: Adjust the opacity of the placeholder */
}

/* Customizing the editor text color */
.ql-editor {
  color: white !important;
}
