.error-popup {
  position: fixed;
  width: 150px;
  top: 20px;
  right: 20px;
  background-color: #d05a5a;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 9999;
  opacity: 0;
  transition: opacity 0.3s ease;
  border: 1px solid red;
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
}

.error-popup.visible {
  opacity: 1;
}

.error-popup.hidden {
  display: none;
}

.close-button-container {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ff3333;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.close-button {
  color: white;
  font-size: 14px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}
