.app-container-sobre-nos {
  width: 100%;
  background-color: #011932;
}

.test-sobre-nos-content {
  width: 100%;
  /* margin-top: 120px; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.text-truncated {
  max-height: 100px; /* Adjust as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.test-sobre-nos-content-column {
  margin-top: 120px;
  margin-bottom: 80px;
}

.test-sobre-nos-text {
  /* font-size: 24px; */
  font-family: "CormorantGaramond-Medium";
  font-weight: 500;
  color: #efede7;
  margin-top: 0;
  /* margin-bottom: 5px; */
}

.test-contacts-content-about-row {
  width: 90%;
  margin-top: 80px;
}

@media (max-width: 839px) {
  .test-contacts-content-about-text-row {
    width: 100%;
    margin-top: 10px;
  }

  .test-contacts-content-about-row {
    flex-direction: column;
  }

  .ceo-image {
    /* height: 300px; */
    height: auto;
    width: 100%;
    margin-top: 30px;
  }
}

@media (min-width: 840px) {
  .test-contacts-content-about-text-row {
    width: 50%;
    margin-top: 80px;
  }

  .test-contacts-content-about-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .ceo-image {
    height: 400px;
  }
}

@media (min-width: 1024px) {
  .test-contacts-content-about-text-row {
    width: 50%;
    margin-top: 80px;
  }

  .ceo-image {
    height: 450px;
  }
}

@media (min-width: 1280px) {
  .test-contacts-content-about-text-row {
    width: 50%;
    margin-top: 80px;
  }

  .ceo-image {
    height: 550px;
  }
}

@media (min-width: 1536px) {
  .test-contacts-content-about-text-row {
    width: 50%;
    margin-top: 80px;
  }

  .ceo-image {
    height: 600px;
  }
}
