.navbar {
  /* position: absolute; */
  top: 0;
  right: 0;
  height: 70px;
  width: 100%;
  /* background-color: transparent; */
  transition: top 0.6s ease;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}

.navbar.top {
  position: absolute;
  background-color: transparent;
}

.navbar.bottom {
  position: fixed;
  background-color: rgb(18, 33, 148);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
}

/* .navbar.scrollDown {
  position: relative;
} */

.navbar.scrollTop {
  position: fixed;
}

.hidden {
  top: -100px;
}

.butoesnavegacao-buttons {
  width: 40%;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.butoesnavegacao-item {
  color: white;
  font-family: "Open Sans", sans-serif;
  font-size: 28px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.butoesnavegacao-item.active,
.butoesnavegacao-item.active:visited {
  color: #c28e2b;
}

.butoesnavegacao-item:hover {
  /* border-bottom: 2px solid #c28e2b; */
  color: #c28e2b;
}

.butoesnavegacao-item-flag {
  color: white;
  font-family: "Open Sans", sans-serif;
  font-size: 28px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 80px;
}

.butoesnavegacao-flag {
  width: 35px;
  height: 35px;
  border-color: transparent;
}

.butoesnavegacao-selected {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.butoesnavegacao-dropdown {
  position: relative;
  display: inline-block;
  width: 135px;
}

/* .butoesnavegacao-dropdown-content {
  top: 100%;
  left:100;
} */

/* .butoesnavegacao-dropdown-content a {
  color: black;
  text-decoration: none;
  display: flex;
  align-items: center;
  position: absolute;
} */

/* .butoesnavegacao-dropdown-item {
  display: flex;
  align-items: center;
} */

.butoesnavegacao-dropdown-content {
  position: absolute;
  top: 30px;
  right: 25px;
}

.butoesnavegacao-dropdown-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
