/* .test-real-estate-image-wrapper {
  height: 60%;
  width: 100%;
  position: relative;
  display: inline-block;
} */

.test-real-estate-content {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 50px;
}

.test-properties-container {
  width: 100%;
  /* margin-bottom: 50px;
  margin-top: 30px; */
  text-decoration: none;
  color: black;
}

.test-properties-container.threeColumns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 60px;
}

.test-real-estate-content-row-recent {
  /* height: 90%; */
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
}

.test-dashboard-title-container {
  width: 100%;
  margin-left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.test-dashboard-real-estate-title {
  left: 0;
  margin: 0;
  /* margin-top: 40px;
  margin-bottom: 30px; */
  font-weight: lighter;
  font-family: "Higuen-Serif";
  color: #c28e2b;
}

.test-properties-container-top {
  /* margin-left: 50px; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.test-properties-container-top.menu-open {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.test-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 50px;
  margin-left: 80px; */
  /* transform: translateX(-50%); */
}

.test-filters-bar-container {
  width: 100%;
  /* margin-left: 50px;
  margin-bottom: 20px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.test-real-estate-page-container {
  width: 100%;
}

.test-search-container {
  position: relative;
  height: 100%;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  border-radius: 10px;
  font-family: "Open Sans", sans-serif;
  background-color: white;
}

.test-search-input {
  height: 100%;
  background-color: transparent;
  border: 0px solid transparent;
}

.test-location-container {
  height: 100%;
  border-left: 1px solid black;
  border-right: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: start;
}

.test-location-button {
  position: relative;
  height: 100%;
  width: 100%;
  padding-left: 15px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
}

.test-arrow-down-icon {
  padding-right: 5px;
  width: 14px;
  height: 8px;
}

.test-arrow-down-icon.close {
  padding-right: 5px;
  width: 14px;
  height: 14px;
}

.test-location-dropdown {
  max-height: 300px;
  width: 350px;
  position: absolute;
  top: calc(100% + 5px);
  left: 400px;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  z-index: 100;
  overflow-y: auto;
}

.test-location-dropdown::-webkit-scrollbar {
  width: 7px; /* largura do scrollbar */
}

.test-location-dropdown::-webkit-scrollbar-track {
  background: transparent; /* cor de fundo do scrollbar */
}

.test-location-dropdown::-webkit-scrollbar-thumb {
  background-color: #888; /* cor do scrollbar */
  border-radius: 6px; /* borda do scrollbar */
}

.test-location-dropdown::-webkit-scrollbar-thumb:hover {
  background: #555; /* cor do scrollbar ao passar o mouse */
}

.test-location-dropdown-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/* Estilo da lista de opções */
.test-location-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.test-location-dropdown ul li {
  margin-top: 0px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
}

/* Estilo de hover para as opções */
.test-location-dropdown ul li:hover {
  background-color: #f0f0f0;
}

.test-location-dropdown ul li.active {
  color: #c28e2b;
}

.test-location-dropdown-mobile {
  max-height: 100%;
  width: 100%;
  left: 400px;
  background-color: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  z-index: 100;
  overflow-y: auto;
}

.test-location-dropdown-mobile::-webkit-scrollbar {
  width: 7px; /* largura do scrollbar */
}

.test-location-dropdown-mobile::-webkit-scrollbar-track {
  background: transparent; /* cor de fundo do scrollbar */
}

.test-location-dropdown-mobile::-webkit-scrollbar-thumb {
  background-color: #888; /* cor do scrollbar */
  border-radius: 6px; /* borda do scrollbar */
}

.test-location-dropdown-mobile::-webkit-scrollbar-thumb:hover {
  background: #555; /* cor do scrollbar ao passar o mouse */
}

.test-location-dropdown-row-mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/* Estilo da lista de opções */
.test-location-dropdown-mobile ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.test-location-dropdown-mobile ul li {
  margin-top: 0px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
}

/* Estilo de hover para as opções */
.test-location-dropdown-mobile ul li:hover {
  background-color: #f0f0f0;
}

.test-location-dropdown-mobile ul li.active {
  color: #c28e2b;
}

.test-search-container {
  height: 36px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: start;
}

.test-search-icon {
  height: 20px;
  right: 30;
  padding-right: 10px;
  padding-left: 10px;
}

.test-sort-dropdown {
  position: absolute;
  top: calc(100% + 5px);
  right: 105px;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  z-index: 100;
}

/* Estilo da lista de opções */
.test-sort-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.test-sort-dropdown ul li {
  margin-top: 0px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
}

/* Estilo de hover para as opções */
.test-sort-dropdown ul li:hover {
  background-color: #f0f0f0;
}

.test-sort-dropdown ul li.active {
  color: #c28e2b;
}

.test-location-checkbox-item {
  flex-shrink: 0;
  margin-right: 10px;
  accent-color: #c28e2b;
  height: 14px;
  width: 14px;
}

.test-filters-menu {
  width: 260px;
  /* background-color: #ffffff; */
  margin-top: 30px;
  z-index: 1;
  /* position: fixed; */
}

.test-filters-menu.fixed {
  margin-top: 0px;
  position: fixed;
  top: 0;
}

.test-filters-menu-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.test-filters-menu-content::-webkit-scrollbar {
  width: 7px; /* largura do scrollbar */
}

.test-filters-menu-content::-webkit-scrollbar-track {
  background: transparent; /* cor de fundo do scrollbar */
}

.test-filters-menu-content::-webkit-scrollbar-thumb {
  background-color: #888; /* cor do scrollbar */
  border-radius: 6px; /* borda do scrollbar */
}

.test-filters-menu-content::-webkit-scrollbar-thumb:hover {
  background: #555; /* cor do scrollbar ao passar o mouse */
}

.test-filters-menu-title {
  color: #ffffff;
  font-size: 23px;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  font-weight: 600;
  margin-top: 0px;
  width: 95%;
  /* letter-spacing: 0cap; */
}

.test-filters-menu-content.fixed {
  margin-top: 25px;
}

.test-filters-menu-options-container {
  width: 95%;
  margin-bottom: 80px;
}

.filters-line-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.test-filters-line {
  border-top: 0.5px solid gray;
}

.test-filters-line.sort {
  align-items: center;
  width: 85%;
  border-top: 0.5px solid gray;
}

.test-filters-menu-final-row {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.test-filters-clear-button {
  border-color: transparent;
  height: 40px;
  width: 150px;
  padding: 8px;
  text-align: center;
  font-size: 17px;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  color: #c28e2b;
  border-radius: 3px;
  border: 1px solid #c28e2b;
  background-color: transparent;
  text-align: start;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.filters-dropdown-row {
  width: 19.8rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.test-header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.test-filters-button {
  position: relative;
  width: 182px;
  border: 0px;
  /* background-color: #c28e2b; */
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
  align-items: center;
}

.test-filters-button-sort {
  position: relative;
  border: 0px;
  /* background-color: #c28e2b; */
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
  align-items: center;
}

.test-filters-text {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* font-family: "SF Pro Display", sans-serif; */
}

.test-filters-text-sort {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* font-family: "SF Pro Display", sans-serif; */
}

.test-filters-bar-content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.test-loading-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 100%;
}

.test-real-estate-content {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 50px;
}

.test-real-estate-content.loading {
  height: 100%;
}

.test-real-estate-image-wrapper {
  height: 60%;
  width: 100%;
  position: relative;
  display: inline-block;
}

.test-real-estate-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}

.test-arrow {
  position: absolute;
  transform: translateY(-50%);
  background-color: #013469;
  color: #c28e2b;
  border: none;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  z-index: 1000;
  bottom: 4rem;
}

.test-properties-home-no-image-container-general-recent-buy {
  background-color: #efede7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0px 0px;
}

@media (max-width: 307px) {
}

@media (min-width: 307px) {
}

@media (min-width: 400px) {
}

@media (max-width: 475px) {
}

@media (min-width: 475px) {
}

@media (min-width: 640px) {
}

@media (max-width: 530px) {
}

@media (min-width: 530px) and (max-width: 767px) {
}

/* @media (min-width: 768px) and (max-width: 839px) {
  .test-dashboard-real-estate-title {
    font-size: 64px;
  }

  .test-search-container {
    width: 45%;
    font-size: 15px;
  }
} */

@media (max-width: 839px) {
  .test-real-estate-content {
    margin-top: 80px;
  }

  .test-dashboard-real-estate-title {
    font-size: 50px;
    margin-bottom: 50px;
  }

  .test-filters-bar-content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .test-search-container {
    width: 100%;
    font-size: 15px;
    margin-bottom: 5px;
  }

  .test-search-input {
    width: 60%;
    padding-left: 15px;
    font-size: 15px;
  }

  .test-location-container {
    width: 40%;
  }

  .test-location-button {
    color: black;
  }

  .test-header-row {
    display: none;
  }

  .test-mobile-filters-row {
    height: 3rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .test-mobile-filters-button {
    height: 1.8rem;
    width: 7rem;
    border-radius: 20px;
    font-size: 15px;
    color: black;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .test-mobile-filters-button-row {
    width: 4.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .test-mobile-filters-sub-row {
    /* width: 11.5rem; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .test-filters-grid-icon {
    width: 23px;
    height: 23px;
  }

  .test-filters-button {
    width: 7.5rem;
    font-size: 15px;
  }

  .test-filters-button-sort {
    width: 6.8rem;
    font-size: 15px;
  }

  .test-filters-icon {
    width: 18px;
    height: 18px;
  }

  .test-real-estate-content-row-recent {
    height: 45%;
  }

  .test-properties-container-top {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .test-properties-container.threeColumns {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    margin-bottom: 30px;
  }

  .button-cancel {
    width: 85px;
    height: 30px;
    font-size: 12px;
  }

  .close-button-confirm {
    width: 85px;
    height: 30px;
    font-size: 12px;
  }

  .pagination-button {
    width: 25px;
    height: 25px;
    font-size: 14px;
  }

  .mobile-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .test-sort-dropdown-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 90%;
    max-height: 90%;
    background: white;
    border-radius: 10px 10px 0 0;
    padding: 20px;
    box-sizing: border-box;
    z-index: 1000;
    animation: slideUp 0.3s ease-out;
  }

  .test-sort-dropdown-mobile.active {
    display: none;
  }

  .test-sort-dropdown-mobile.closing {
    animation: slideDown 0.3s ease-out;
  }

  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes slideDown {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(100%);
    }
  }

  .test-sort-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .test-sort-header-title {
    font-size: 25px;
    font-weight: bold;
    font-family: "SF Pro Display", sans-serif;
  }

  .test-sort-close-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #000; /* Cor do ícone, ajuste conforme necessário */
    padding: 0; /* Remove o padding padrão */
    display: flex; /* Alinha o botão ao centro verticalmente */
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-left: auto; /* Empurra o botão para o lado direito */
    font-size: 0; /* Remove o espaço ao redor do SVG */
  }

  .test-sort-close-button svg {
    width: 24px;
    height: 24px;
  }

  .test-sort-close-button:hover {
    color: #c28e2b;
  }

  /* Estilos específicos para a lista de opções */
  .sort-options-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .sort-options-list li {
    margin-top: 0px;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
      "Arial", sans-serif;
  }

  /* Estilo de hover para as opções */
  .sort-options-list li:hover {
    background-color: #f0f0f0;
  }

  .sort-options-list li.active {
    color: #c28e2b;
  }

  .filters-line-container {
    padding: 0;
    margin: 0;
  }

  .filters-line {
    height: 1px;
    background: #ddd;
  }

  .test-checkbox-item {
    flex-shrink: 0;
    margin-right: 10px;
    accent-color: #c28e2b;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid black;
    outline: none;
    cursor: pointer;
    position: relative;
  }

  .test-checkbox-item:checked {
    border: 1px solid #c28e2b;
    background-color: #c28e2b;
  }

  .test-checkbox-item:checked::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  .properties-error {
    font-size: 15px;
  }

  .test-dashboard-real-estate-title.loading {
    margin: 0;
    padding-top: 65px;
  }

  .test-real-estate-image {
    height: 75%;
  }

  .test-arrow {
    bottom: 3.5rem;
  }

  .test-properties-home-no-image-container-general-recent-buy {
    height: 13rem;
  }
}

@media (min-width: 840px) {
  .test-dashboard-real-estate-title {
    font-size: 64px;
    margin-top: 50px;
    margin-bottom: 80px;
  }

  .test-dashboard-real-estate-title.loading {
    margin: 0;
    padding-top: 125px;
  }

  .test-search-container {
    width: 45%;
    font-size: 15px;
  }

  .test-search-input {
    width: 60%;
    padding-left: 15px;
    font-size: 15px;
  }

  .test-location-container {
    width: 35%;
  }

  .test-header-row {
    width: 30%;
  }

  .test-header-row {
    width: 23rem;
  }

  .test-filters-button {
    width: 140px;
    font-size: 18px;
  }

  .test-filters-button-sort {
    width: 130px;
    font-size: 18px;
  }

  .test-filters-grid-icon {
    width: 23px;
    height: 23px;
  }

  .test-filters-icon {
    width: 18px;
    height: 18px;
  }

  .test-mobile-filters-button {
    display: none;
  }

  .test-mobile-filters-row {
    display: none;
  }

  .test-filters-bar-container {
    margin-bottom: 50px;
  }

  .test-properties-container-top {
    margin-bottom: 50px;
  }

  .test-properties-home-no-image-container-general-recent-buy {
    height: 100%;
  }
}

@media (min-width: 1024px) {
  .test-dashboard-real-estate-title {
    font-size: 66px;
    margin-top: 50px;
    margin-bottom: 80px;
  }

  .test-search-container {
    width: 45%;
    font-size: 15px;
  }

  .test-search-input {
    width: 60%;
    padding-left: 15px;
    font-size: 15px;
  }

  .test-location-container {
    width: 35%;
  }

  .test-header-row {
    width: 23rem;
  }

  .test-filters-button {
    width: 140px;
    font-size: 18px;
  }

  .test-filters-button-sort {
    width: 130px;
    font-size: 18px;
  }

  .test-filters-grid-icon {
    width: 23px;
    height: 23px;
  }

  .test-filters-icon {
    width: 18px;
    height: 18px;
  }
}

@media (min-width: 1280px) {
  .test-dashboard-real-estate-title {
    font-size: 68px;
    margin-top: 50px;
    margin-bottom: 80px;
  }

  .test-search-container {
    width: 45%;
    font-size: 15px;
  }

  .test-search-input {
    width: 60%;
    padding-left: 15px;
    font-size: 15px;
  }

  .test-location-container {
    width: 35%;
  }

  .test-header-row {
    width: 25rem;
  }

  .test-filters-button {
    width: 150px;
    font-size: 20px;
  }

  .test-filters-button-sort {
    width: 140px;
    font-size: 20px;
  }

  .test-filters-grid-icon {
    width: 25px;
    height: 25px;
  }

  .test-filters-icon {
    width: 20px;
    height: 20px;
  }
}

@media (min-width: 1536px) {
  .test-dashboard-real-estate-title {
    font-size: 70px;
    margin-top: 50px;
    margin-bottom: 80px;
  }

  .test-search-container {
    width: 45%;
    font-size: 15px;
  }

  .test-search-input {
    width: 60%;
    padding-left: 15px;
    font-size: 15px;
  }

  .test-location-container {
    width: 35%;
  }

  .test-header-row {
    width: 25rem;
  }

  .test-filters-button {
    width: 150px;
    font-size: 20px;
  }

  .test-filters-button-sort {
    width: 140px;
    font-size: 20px;
  }

  .test-filters-grid-icon {
    width: 25px;
    height: 25px;
  }

  .test-filters-icon {
    width: 20px;
    height: 20px;
  }
}
