.page-not-found-container {
  height: 100%;
  background-color: #011932;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-not-found-icon {
  height: 20vh;
  width: 20vh;
}

.page-not-found-title {
  font-size: 4.5vh;
  margin-top: 8vh;
  margin-bottom: 1vh;
  font-weight: lighter;
  color: #c28e2b;
  font-family: "BDSans-Regular";
  font-weight: 800;
}

.soon-title {
  font-size: 4.5vh;
  margin-top: 8vh;
  margin-bottom: 1vh;
  color: #c28e2b;
  font-family: "BDSans-Regular";
  font-weight: 800;
}

.page-not-found-text {
  width: 80vh;
  font-size: 2.5vh;
  font-weight: lighter;
  margin-top: 1vh;
  margin-bottom: 1vh;
  color: white;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  letter-spacing: 0.1;
}

.soon-text {
  width: 75vh;
  font-size: 2.5vh;
  font-weight: lighter;
  margin-top: 1vh;
  margin-bottom: 1vh;
  color: white;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  letter-spacing: 0.1;
}

.soon-sub-text {
  width: 38vh;
  font-size: 1.95vh;
  font-weight: lighter;
  margin-top: 1vh;
  margin-bottom: 1vh;
  color: white;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  letter-spacing: 0.1;
}

.soon-sub-text-en {
  width: 42vh;
  font-size: 1.95vh;
  font-weight: lighter;
  margin-top: 1vh;
  margin-bottom: 1vh;
  color: white;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  letter-spacing: 0.1;
}

.clock-container {
  position: relative;
}

.clock {
  border-radius: 70px;
  border: 3px solid #c28e2b;
  position: relative;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  display: block;
  width: 120px;
  height: 120px;
}

.clock:after {
  content: "";
  position: absolute;
  background-color: #c28e2b;
  top: 10px;
  left: 48%;
  height: 50px;
  width: 4px;
  border-radius: 5px;
  -webkit-transform-origin: 50% 97%;
  transform-origin: 50% 97%;
  -webkit-animation: grdAiguille 5s linear infinite;
  animation: grdAiguille 5s linear infinite;
}

@-webkit-keyframes grdAiguille {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes grdAiguille {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.clock:before {
  content: "";
  position: absolute;
  background-color: #c28e2b;
  top: 21px;
  left: 48%;
  height: 40px;
  width: 4px;
  border-radius: 5px;
  -webkit-transform-origin: 50% 94%;
  transform-origin: 50% 94%;
  -webkit-animation: ptAiguille 15s linear infinite;
  animation: ptAiguille 15s linear infinite;
}

@-webkit-keyframes ptAiguille {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes ptAiguille {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* .clock-face {
  position: relative;
  width: 100%;
  height: 100%;
}

.hand {
  width: 50%;
  height: 4px;
  background-color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: left center;
  animation: rotate 10s linear infinite;
}

.hour-marks {
  position: absolute;
  width: 100%;
  height: 100%;
}

.mark {
  width: 4px;
  height: 100%;
  background-color: black;
  position: absolute;
  top: 0;
  left: 50%;
  transform-origin: bottom center;
}

.mark1 {
  transform: rotate(30deg) translateY(-100%);
}
.mark2 {
  transform: rotate(60deg) translateY(-100%);
}
.mark3 {
  transform: rotate(90deg) translateY(-100%);
}
.mark4 {
  transform: rotate(120deg) translateY(-100%);
}
.mark5 {
  transform: rotate(150deg) translateY(-100%);
}
.mark6 {
  transform: rotate(180deg) translateY(-100%);
}
.mark7 {
  transform: rotate(210deg) translateY(-100%);
}
.mark8 {
  transform: rotate(240deg) translateY(-100%);
}
.mark9 {
  transform: rotate(270deg) translateY(-100%);
}
.mark10 {
  transform: rotate(300deg) translateY(-100%);
}
.mark11 {
  transform: rotate(330deg) translateY(-100%);
}
.mark12 {
  transform: rotate(360deg) translateY(-100%);
}

@keyframes rotate {
  from {
    transform: rotate(0deg) translate(-50%, -50%);
  }
  to {
    transform: rotate(360deg) translate(-50%, -50%);
  }
} */

@media (max-width: 768px) {
  .page-not-found-icon {
    height: 12vh;
    width: 12vh;
  }

  .page-not-found-title {
    width: 48vh;
    text-align: center;
    font-size: 2.8vh;
  }

  .page-not-found-text {
    font-size: 2vh;
    width: 42vh;
  }

  .soon-title {
    width: 48vh;
    text-align: center;
    font-size: 2.8vh;
  }

  .soon-text {
    font-size: 2vh;
    width: 42vh;
  }

  .soon-sub-text {
    width: 34vh;
    font-size: 1.8vh;
  }

  .soon-sub-text-en {
    width: 34vh;
    font-size: 1.8vh;
  }

  .soon-social-media-bar {
    width: 28vh;
  }

  .mediabar-icon {
    width: 5vh;
    height: auto;
  }

  .clock {
    margin-left: -60px;
    margin-top: -50px;
  }
}
