.social-media-bar {
  position: absolute;
  top: 200;
  right: 100;
  width: 350px;
  z-index: 10;
}

.soon-social-media-bar {
  top: 200;
  right: 100;
  width: 32.5vh;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.soon-link {
  color: #c28e2b;
}

.social-media-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.social-media-item {
  color: white;
  font-family: "Open Sans", sans-serif;
  font-size: 28px;
  text-decoration: none;
  cursor: pointer;
}

.mediabar-icon {
  width: 5.5vh;
  height: auto;
}

.whatsapp-button {
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
}
